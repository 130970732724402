import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from '@material-ui/core';
import arrayMutators from 'final-form-arrays';
import { observer } from 'mobx-react-lite';
import {
  Checkboxes,
  makeRequired,
  makeValidate,
  Select,
  TextField,
  TextFieldProps,
} from 'mui-rff';
import React, { useEffect, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { CurrencyField } from '../../components/CurrencyField';
import { FormHeader } from '../../components/FormHeader';
import { ImageUpload } from '../../components/ImageUpload';
import { CommonModal } from '../../components/Modals/CommonModal';
import { PATHS } from '../../constants';
import { useStore } from '../../stores/index';
import { IOwner } from '../../types/Owner';
import { ISkybox } from '../../types/Skybox';
import { getSkyboxTypeSelectOptions } from '../../utils/formUtils';
import { Validator } from '../../utils/validation';

interface SkyboxFormProps {
  ownerList?: IOwner[];
  initialValues?: ISkybox;
  isLoading: boolean;
  onCancel: () => void;
  onSubmit: (values: any, form: any, callback: any) => void;
  type?: 'add' | 'edit';
}

interface WrappedSkyboxFormProps extends SkyboxFormProps {
  id: string;
  isAdmin: any;
  isOwner: any;
  addAsset: any;
  removeAsset: any;
  myCompanyData?: IOwner;
  deactivateSkybox: (id: string) => Promise<void>;
}

const getDefaultValues = (values?: ISkybox) => ({
  id: values?.id || undefined,
  skyboxName: values?.name || '',
  seatCount: values?.seatCount || undefined,
  mapId: values?.mapId || '',
  ownerCompanyId: values?.ownerCompanyId || '',
  defaultPrice: values?.defaultPrice || undefined,
  type: values?.type || 'whole_sale',
  listable: values?.listable || false,
  contactName: values?.contactName || '',
  contactEmail: values?.contactEmail || '',
  blockId: values?.blockId || '',
  contactPhone: values?.contactPhone || '',
  description: values?.description || '',
  ownerDescription: values?.ownerDescription || '',
  facilities: values?.facilities || [],
  featuredAsset: values?.featuredAsset || undefined,
  logoAsset: values?.logoAsset || undefined,
  assets: values?.assets || undefined,
  eventimPromoSeatId: values?.eventimPromoSeatId || '',
  promoTicketPriceLevelText: values?.promoTicketPriceLevelText || '',
  eventimInHouseSeatId: values?.eventimInHouseSeatId || '',
  inhouseTicketPriceLevelText: values?.inhouseTicketPriceLevelText || '',
  secondaryOwners: values?.secondaryOwners || [],
  systemIdTicketPriceLevelTexts: values?.systemIdTicketPriceLevelTexts || [],
  vatPercentage: values?.vatPercentage || undefined,
});

const SystemIdTicketPriceLevelTextsInput = () => {
  const { t } = useTranslation();

  return (
    <FieldArray name="systemIdTicketPriceLevelTexts">
      {({ fields }) => (
        <Box gridRowGap={2} mb={3}>
          {fields.map((name, index) => (
            <Box key={name}>
              <Grid
                container
                spacing={4}
                alignContent={'flex-end'}
                justify={'flex-end'}
              >
                <Grid item xs={5}>
                  <Field
                    name={`${name}.systemId`}
                    parse={(value) => (value === '' ? value : Number(value))}
                    render={({ input, meta }) => (
                      <TextField
                        {...input}
                        label={t('skybox.systemId')}
                        type="number"
                        required
                        error={meta.touched && meta.error}
                        helperText={
                          meta.touched && meta.error ? meta.error : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label={t('skybox.priceLevelText')}
                    name={`${name}.priceLevelText`}
                    type="text"
                    required
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  alignItems={'flex-start'}
                  style={{ display: 'flex' }}
                >
                  <Box pt={2}>
                    <IconButton onClick={() => fields.remove(index)}>
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        style={{ width: 16 }}
                      />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))}
          <Box mt={2}>
            <Button
              type="button"
              variant="outlined"
              onClick={() => fields.push({})}
            >
              {t('common.add')}
            </Button>
          </Box>
        </Box>
      )}
    </FieldArray>
  );
};
const FacilitiesInput = () => {
  const { t } = useTranslation();

  return (
    <FieldArray name="facilities">
      {({ fields }) => (
        <Box gridRowGap={2} mb={3} mt={2}>
          {fields.map((name, index) => (
            <Box key={name}>
              <Grid container spacing={4} justify={'flex-start'}>
                <Grid item xs={5}>
                  <TextField
                    label={t('skybox.facilityDescription')}
                    name={`${name}.description`}
                    type="text"
                    required
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  alignItems={'flex-start'}
                  style={{ display: 'flex' }}
                >
                  <Box pt={2}>
                    <IconButton onClick={() => fields.remove(index)}>
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        style={{ width: 16 }}
                      />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))}
          <Box mt={2}>
            <Button
              type="button"
              variant="outlined"
              onClick={() => fields.push({})}
            >
              {t('common.add')}
            </Button>
          </Box>
        </Box>
      )}
    </FieldArray>
  );
};

export const WrappedSkyboxForm = observer(
  ({
    id,
    type,
    initialValues,
    ownerList,
    isLoading,
    onSubmit,
    onCancel,
    isAdmin,
    isOwner,
    addAsset,
    removeAsset,
    deactivateSkybox,
    myCompanyData,
  }: WrappedSkyboxFormProps) => {
    const DESCRIPTION_MAX_LENGTH = 500;

    const { t } = useTranslation();
    const history = useHistory();
    const [featuredPreviewImage, setFeaturedPreviewImage] = useState(null);
    const [logoPreviewImage, setLogoPreviewImage] = useState(null);
    const [deactivateConfirmOpen, setDeactivateConfirmOpen] = useState(false);

    useEffect(() => {
      if (!initialValues || !myCompanyData || !isOwner) return;
      if (initialValues.ownerCompanyId !== myCompanyData.id) {
        history.push(PATHS.skyboxes.main);
      }
    }, [initialValues, myCompanyData, isOwner, history]);

    const taxPercentages = [10, 25.5];
    const taxPercentageOptions = taxPercentages.map((val) => ({
      value: val,
      label: `${val}%`,
    }));

    const schema = Validator.object().shape({
      skyboxName: Validator.string().required(),
      seatCount: Validator.number().when('type', {
        is: (type: string) => type === 'whole_sale',
        then: Validator.number().positive().required(),
        otherwise: Validator.number(),
      }),
      mapId: Validator.string().required(),
      eventimPromoSeatId: Validator.string().when('type', {
        is: (type: string) => type === 'whole_sale',
        then: Validator.string().required(),
        otherwise: Validator.string(),
      }),
      eventimInHouseSeatId: Validator.string().when('type', {
        is: (type: string) => type === 'whole_sale',
        then: Validator.string().required(),
        otherwise: Validator.string(),
      }),
      inhouseTicketPriceLevelText: Validator.string().when('type', {
        is: (type: string) => type === 'whole_sale',
        then: Validator.string().required(),
        otherwise: Validator.string(),
      }),
      promoTicketPriceLevelText: Validator.string().when('type', {
        is: (type: string) => type === 'whole_sale',
        then: Validator.string().required(),
        otherwise: Validator.string(),
      }),
      ownerCompanyId: Validator.string().required(),
      type: Validator.string().required(),
      blockId: Validator.string().required(),
      defaultPrice: Validator.number().when('type', {
        is: (type: string) => type === 'whole_sale',
        then: Validator.number().positive().required(),
        otherwise: Validator.number(),
      }),
      contactName: Validator.string(),
      contactEmail: Validator.string().email(),
      contactPhone: Validator.string().phoneNumber(),
      description: Validator.string().max(DESCRIPTION_MAX_LENGTH),
      ownerDescription: Validator.string().max(DESCRIPTION_MAX_LENGTH),
      listable: Validator.boolean(),
      systemIdTicketPriceLevelTexts: Validator.array().of(
        Validator.object().shape({
          systemId: Validator.number()
            .transform((value) => (Number.isNaN(value) ? undefined : value))
            .required(),
          priceLevelText: Validator.string().required(),
        }),
      ),
      facilities: Validator.array().of(
        Validator.object().shape({
          description: Validator.string().required(),
        }),
      ),
      taxPercentage: Validator.number(),
    });

    const validate = makeValidate(schema);
    const required = makeRequired(schema);
    const { id: idParam }: { id: string } = useParams();

    const ownerOptions = useMemo(() => {
      if (ownerList) {
        return ownerList.map((owner) => ({
          value: owner.id,
          label: owner.name,
        }));
      }
      return [];
    }, [ownerList]);

    const defaultValues = React.useMemo(() => {
      const values = getDefaultValues(initialValues);
      return values;
    }, [initialValues]);

    const formHeaderTranslationKey =
      type !== 'add' ? 'skybox.editSkybox' : 'skybox.addNewSkybox';

    const renderHeading = (label: string) => {
      return (
        <Grid item xs={12}>
          <Typography variant="h3" paragraph>
            {t(label)}
          </Typography>
        </Grid>
      );
    };

    const renderField = (
      name: string,
      label?: string,
      opts?: {
        halfWidth?: boolean;
        disabled?: boolean;
        required?: boolean;
        helperText?: string;
        type?: TextFieldProps['type'];
      },
    ) => {
      const size = opts?.halfWidth ? 6 : 12;
      const resolvedLabel = label || `skybox.${name}`;
      return (
        <Grid item xs={size}>
          <TextField
            id={name}
            name={name}
            label={t(resolvedLabel)}
            required={opts?.required}
            disabled={opts?.disabled}
            type={opts?.type ?? 'text'}
          />
          {opts?.helperText && (
            <FormHelperText>{opts?.helperText}</FormHelperText>
          )}
        </Grid>
      );
    };

    const isWholeSaleSkybox = (values: any) => values.type === 'whole_sale';

    return (
      <Form
        key={'skyboxForm'}
        initialValues={defaultValues}
        validate={validate}
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
        render={({ handleSubmit, form: { change }, values, submitting }) => {
          const onFeaturedImageChange = ({
            image,
            imagePreviewUrl,
          }: {
            image: any;
            imagePreviewUrl: any;
          }) => {
            change('newFeaturedAsset', image);
            setFeaturedPreviewImage(imagePreviewUrl);
          };

          const onFeaturedImageDelete = () => {
            // @ts-ignore
            change('featuredAsset', null);
            setFeaturedPreviewImage(null);
          };

          const handleDeactivateConfirm = async () => {
            await deactivateSkybox(idParam);
            history.push(PATHS.skyboxes.main);
          };
          const handleDeactivateCancel = () => {
            setDeactivateConfirmOpen(false);
          };
          const handleDeactivateClick = () => {
            setDeactivateConfirmOpen(true);
          };

          const onLogoChange = ({
            image,
            imagePreviewUrl,
          }: {
            image: any;
            imagePreviewUrl: any;
          }) => {
            change('newLogoAsset', image);
            setLogoPreviewImage(imagePreviewUrl);
          };

          const onLogoDelete = () => {
            // @ts-ignore
            change('logoAsset', null);
            setLogoPreviewImage(null);
          };

          const onAdditionalImageChange = ({ image, imagePreviewUrl }: any) => {
            addAsset({ image }, id);
            const newAssets = [
              ...(values.assets ?? []),
              ...[{ source: imagePreviewUrl }],
            ];
            // @ts-ignore
            change('assets', newAssets);
          };

          const onAdditionalImageDelete = (assetId: string) => {
            removeAsset({ assetId, skyboxId: id });
            // @ts-ignore
            change(
              'assets',
              // @ts-ignore
              values.assets?.filter((asset: any) => asset.id !== assetId),
            );
          };

          if (values.type === 'group_sale') {
            change('defaultPrice', undefined);
          }

          const dropdownItems = useMemo(() => {
            if (!idParam) {
              return undefined;
            }
            return [
              {
                onClick: handleDeactivateClick,
                label: t('skybox.deactivateSkybox'),
              },
            ];
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [idParam, t]);

          return (
            <form onSubmit={handleSubmit} noValidate>
              <FormHeader
                onCancel={onCancel}
                title={t(formHeaderTranslationKey)}
                loading={submitting || isLoading}
                dropdownItems={isAdmin ? dropdownItems : undefined}
              />
              <Box p={4}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <Field name="assets" component="input" type="hidden" />
                    <Grid container spacing={3}>
                      {renderHeading('skybox.basicInformation')}
                      {renderField('skyboxName', 'skybox.skyboxName', {
                        required: true,
                      })}

                      <Grid item xs={12}>
                        <Select
                          id="type"
                          name="type"
                          label={t('skybox.type')}
                          required={required.type}
                          disabled={isOwner}
                        >
                          {getSkyboxTypeSelectOptions(t).map((option) => (
                            <MenuItem value={option.value} key={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>

                      {isWholeSaleSkybox(values) &&
                        renderHeading('skybox.systemInformation')}

                      {renderField('mapId', 'common.id', {
                        halfWidth: true,
                        required: true,
                        disabled: !!isOwner,
                      })}
                      {renderField('blockId', 'skybox.blockId', {
                        halfWidth: true,
                        required: true,
                        disabled: !!isOwner,
                      })}
                      {isWholeSaleSkybox(values) &&
                        renderField('seatCount', 'common.capacity', {
                          required: true,
                          halfWidth: true,
                          disabled: !!isOwner,
                          type: 'number',
                        })}
                      {!isOwner &&
                        isWholeSaleSkybox(values) &&
                        renderField('eventimPromoSeatId', 'skybox.promoterId', {
                          required: true,
                          halfWidth: true,
                          disabled: !!isOwner,
                          helperText: t('skybox.idHelper'),
                        })}
                      {isWholeSaleSkybox(values) &&
                        !isOwner &&
                        renderField(
                          'promoTicketPriceLevelText',
                          'skybox.promoTicketPriceLevelText',
                          {
                            required: true,
                            halfWidth: true,
                            disabled: !!isOwner,
                          },
                        )}
                      {isWholeSaleSkybox(values) &&
                        !isOwner &&
                        renderField(
                          'eventimInHouseSeatId',
                          'skybox.inhouseId',
                          {
                            required: true,
                            halfWidth: true,
                            disabled: !!isOwner,
                          },
                        )}
                      {isWholeSaleSkybox(values) &&
                        !isOwner &&
                        renderField(
                          'inhouseTicketPriceLevelText',
                          'skybox.inhouseTicketPriceLevelText',
                          {
                            required: true,
                            halfWidth: true,
                            disabled: !!isOwner,
                          },
                        )}

                      {isWholeSaleSkybox(values) && !isOwner && (
                        <Grid item xs={12}>
                          <Typography variant="h5" paragraph>
                            {t('skybox.systemIdPriceLevelTextsLabel')}
                          </Typography>
                          <SystemIdTicketPriceLevelTextsInput />
                        </Grid>
                      )}

                      {isAdmin && (
                        <>
                          {renderHeading('skybox.ownersInformation')}
                          <Grid item xs={6}>
                            <Select
                              id="ownerCompanyId"
                              name="ownerCompanyId"
                              label={t('common.owner')}
                              required={required.ownerCompanyId}
                              data={ownerOptions}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Select
                              id="secondaryOwners"
                              name="secondaryOwners"
                              label={t('skybox.secondaryOwner')}
                              data={ownerOptions}
                              multiple
                            />
                          </Grid>
                        </>
                      )}

                      {isWholeSaleSkybox(values) && (
                        <>
                          {renderHeading('skybox.rental')}
                          <Grid item xs={4}>
                            <Select
                              name="vatPercentage"
                              label={t('products.vat')}
                              required
                              disabled={isOwner}
                            >
                              {taxPercentageOptions.map((option) => (
                                <MenuItem value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                          <Grid item xs={6}>
                            <CurrencyField
                              name="defaultPrice"
                              label={t('skybox.defaultPrice')}
                              required
                              helperText={t('common.priceInclVAT', {
                                vat: values.vatPercentage,
                              })}
                            />
                          </Grid>
                        </>
                      )}

                      {isWholeSaleSkybox(values) && (
                        <>
                          {renderHeading('skybox.list')}
                          <Grid item xs={12}>
                            <Typography paragraph>
                              {t('skybox.listableText')}
                            </Typography>
                            <Checkboxes
                              name="listable"
                              data={{
                                label: t('skybox.listable'),
                                value: values.listable || false,
                              }}
                            />
                          </Grid>
                        </>
                      )}

                      {isWholeSaleSkybox(values) &&
                        renderHeading('skybox.contactPerson')}
                      {isWholeSaleSkybox(values) &&
                        renderField('contactName', 'common.name')}
                      {isWholeSaleSkybox(values) &&
                        renderField('contactEmail', 'common.email', {
                          halfWidth: true,
                          type: 'email',
                        })}
                      {isWholeSaleSkybox(values) &&
                        renderField('contactPhone', 'common.phone', {
                          halfWidth: true,
                          type: 'tel',
                        })}
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Grid container spacing={3}>
                      {renderHeading('skybox.skyboxIntroduction')}
                      <Grid item xs={12}>
                        <TextField
                          id="description"
                          name="description"
                          label={t('skybox.skyboxIntroductionText')}
                          multiline
                          rows={10}
                          disabled={isOwner}
                          required={required.description}
                        />

                        <Box mb={3} />

                        <TextField
                          id="ownerDescription"
                          name="ownerDescription"
                          label={t('skybox.ownerDescription')}
                          helperText={t('skybox.ownerDescriptionHelper')}
                          multiline
                          rows={10}
                          required={required.ownerDescription}
                          inputProps={{
                            maxLength: DESCRIPTION_MAX_LENGTH,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="h5" paragraph>
                          {t('skybox.facilities')}
                        </Typography>
                        <FormHelperText>
                          {t('skybox.facilityHelperText')}
                        </FormHelperText>
                        <FacilitiesInput />
                      </Grid>

                      {renderHeading('skybox.images')}

                      <Grid item xs={12}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <ImageUpload
                              label={t('skybox.mainImage')}
                              name="featuredAsset"
                              onChange={onFeaturedImageChange}
                              onDelete={onFeaturedImageDelete}
                              previewUrl={featuredPreviewImage}
                              addLabel={t('skybox.addNewMainImage')}
                            />
                          </Grid>
                        </Grid>

                        <Box mt={3}>
                          <ImageUpload
                            label={t('skybox.logo')}
                            name="logoAsset"
                            onChange={onLogoChange}
                            onDelete={onLogoDelete}
                            previewUrl={logoPreviewImage}
                            addLabel={t('skybox.addNewLogo')}
                            helperText={t('skybox.logoNotRequired')}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <ImageUpload
                          label={t('skybox.images')}
                          name="assets"
                          onChange={onAdditionalImageChange}
                          onAdditionalImageDelete={onAdditionalImageDelete}
                          addLabel={t('skybox.addNewImage')}
                          multiple
                          disabled={type === 'add'}
                          assets={values.assets}
                          helperText={
                            type === 'add'
                              ? t('skybox.saveToAddImages')
                              : undefined
                          }
                          maxAssetCount={8}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <CommonModal
                type="prompt"
                open={deactivateConfirmOpen}
                onConfirm={handleDeactivateConfirm}
                onCancel={handleDeactivateCancel}
                confirmText={t('skybox.deactivateModal.confirm')}
                title={t('skybox.deactivateModal.title')}
              >
                <>
                  {t('skybox.deactivateModal.body', {
                    name: initialValues?.name,
                  })}
                </>
              </CommonModal>
            </form>
          );
        }}
      />
    );
  },
);

export const SkyboxForm: React.FC<SkyboxFormProps> = observer((props) => {
  const { id } = useParams() as any;
  const { isAdmin, isOwner } = useStore('authStore');
  const { addAsset, removeAsset, deactivateSkybox } = useStore('skyboxStore');
  const { myCompanyData } = useStore('settingsStore');
  return (
    <WrappedSkyboxForm
      id={id}
      isAdmin={isAdmin}
      isOwner={isOwner}
      addAsset={addAsset}
      removeAsset={removeAsset}
      myCompanyData={myCompanyData as any}
      deactivateSkybox={deactivateSkybox}
      {...props}
    />
  );
});
