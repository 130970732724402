import { Box, makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';
import { Toast } from '.';
import { useStores } from '../../stores';
import { theme } from '../../styles';
import { IToast } from '../../types/Common';

interface Props {}

const useStyles = makeStyles({
  toastContainer: {
    position: 'fixed',
    height: 'auto',
    right: theme.spacing(2),
    top: 76,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    zIndex: 1400,
  },
});
const ToastProvider: FC<Props> = observer(() => {
  const {
    toastStore: { toasts, closeToasts, closeToast },
  } = useStores();
  const classes = useStyles();
  useEffect(() => {
    return () => {
      closeToasts();
    };
  }, [closeToasts]);

  const onClose = (id: string) => {
    closeToast(id);
  };

  return (
    <Box className={classes.toastContainer}>
      {toasts.map((toast: IToast, index: number) => (
        <Toast
          id={toast.id}
          onClose={onClose}
          key={toast.id}
          index={index}
          type={toast.type}
          msg={toast.msg}
          values={toast.values}
        />
      ))}
    </Box>
  );
});

export { ToastProvider };
