/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import {
  Box,
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFilters, useMountedLayoutEffect, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import Pagination from "../Pagination/Pagination";
import FilterField from "./MaterialTableFilterField";
import TableHead from "./MaterialTableHead";

let prevSelectedCount = 0;
let prevSelected = undefined;
let prevPageSize = undefined;

const useStyles = makeStyles({
  spinner: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  pagination: {
    backgroundColor: "#fff",
  },
  container: (props) => ({
    position: "relative",
    flexGrow: "1",
    width: "unset",
    maxWidth: "100%",
    ...(props.maxWidth ? { width: "100%" } : {}),
    alignSelf: "flex-start",
    overflowX: "auto",
    marginBottom: 12,
  }),
  table: (props) => ({
    width: "unset",
    maxWidth: "100%",
    ...(props.maxWidth ? { width: "100%" } : {}),
  }),
  root: {
    width: "100%",
    maxHeight: "100%",
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
  },
  unselectedRow: {
    "& > td:not(:first-child)": {
      opacity: 0.5,
    },
  },
});

/**
 * @param {string} [data]
 * @return {(string|undefined)}
 */
const defaultGetRowTestId = (rowData) => undefined;

const MaterialTable = ({
  data,
  columns,
  totalCount,
  onPageChange,
  onSortingChange,
  onFilteringChange,
  onPageSizeChange,
  isLoading,
  footerRow,
  getRowProps,
  defaultPage,
  defaultPageSize,
  paginationEnabled,
  fixed,
  maxWidth,
  onRowSelect,
  checkboxColumnLabel,
  onRemoveLastSelection,
  selectedIds,
  disabledIds,
  controlledPageIndex,
  useControlledState,
  checkboxAsRadio,
  loading,
  emptySortByEnabled,
  initialState: initState,
  getRowTestId = defaultGetRowTestId,
}) => {
  const { t } = useTranslation();
  const getSelectedCheckboxesObj = useCallback(
    (selectedIds, data) => {
      if (!selectedIds) return;
      const obj = {};
      selectedIds.map((id) => {
        const dataIndex = data.findIndex((item) => item.id === id);
        obj[dataIndex] = true;
        return null;
      });
      return obj;
    },
    [selectedIds, data]
  );

  const getSelectedIdsFromObj = (selectedKeys, data) => {
    return Object.keys(selectedKeys)
      .map((selectedIndex) => {
        if (!data || !data[selectedIndex]) return null;
        return data[selectedIndex].id;
      })
      .filter((val) => val);
  };

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: FilterField,
    }),
    []
  );

  const plugins = [];
  const afterPaginationPlugins = [];
  const initialState = {
    ...{
      pageIndex: defaultPage,
      pageSize: defaultPageSize,
      selectedRowIds: getSelectedCheckboxesObj(selectedIds, data),
    },
    ...(initState && initState),
  };

  if (onFilteringChange) {
    plugins.push(useFilters);
    initialState.filters = [];
  }

  if (onSortingChange) {
    plugins.push(useSortBy);
    if (!initState?.sortBy) initialState.sortBy = [];
  }

  if (onRowSelect) {
    afterPaginationPlugins.push(useRowSelect);
  }

  const classes = useStyles({ maxWidth });

  const onCheckboxClick = (e, onChange, checked, isToggleAll) => {
    if (onRemoveLastSelection) {
      if (((isToggleAll && !checked) || (!isToggleAll && prevSelectedCount === 1)) && !checked) {
        onRemoveLastSelection();
        return false;
      }
    }
    onChange(e);
  };

  const isDisabled = (id) => {
    return disabledIds ? disabledIds.includes(id) : false;
  };
  const opts = {
    data,
    columns,
    manualSortBy: !!onSortingChange,
    manualPagination: true,
    manualFilters: !!onFilteringChange,
    pageCount: totalCount,
    autoResetPage: false,
    defaultColumn,
    initialState,
  };

  if (useControlledState) {
    opts.useControlledState = (state) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useMemo(
        () => ({
          ...state,
          pageIndex: controlledPageIndex,
        }),
        [state, controlledPageIndex]
      );
    };
  }
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setPageSize,
    gotoPage,
    state: { sortBy, pageIndex, pageSize, filters, selectedRowIds },
  } = useTable({ ...opts }, ...plugins, usePagination, ...afterPaginationPlugins, (hooks) => {
    if (!onRowSelect) return null;
    hooks.visibleColumns.push((columns) => [
      // Let's make a column for selection
      {
        style: {
          maxWidth: 70,
          width: 70,
        },
        id: "selection",
        Header: ({ getToggleAllRowsSelectedProps }) => {
          const { onChange, ...rest } = getToggleAllRowsSelectedProps();
          return (
            <Box display="flex" alignItems="center">
              {!checkboxAsRadio && (
                <Checkbox onChange={(e, checked) => onCheckboxClick(e, onChange, checked, true)} {...rest} />
              )}
              {checkboxColumnLabel}
            </Box>
          );
        },
        Cell: ({ row }) => {
          const { onChange, ...rest } = row.getToggleRowSelectedProps();
          return (
            <Checkbox
              disabled={isDisabled(row.id)}
              onChange={(e, checked) => onCheckboxClick(e, onChange, checked)}
              {...rest}
            />
          );
        },
      },
      ...columns,
    ]);
  });

  /**
   * Resets prev page size on unmount
   */
  useEffect(() => {
    return () => {
      prevPageSize = undefined;
    };
  }, []);

  useMountedLayoutEffect(() => {
    if (JSON.stringify(prevSelected) === JSON.stringify(selectedRowIds)) return;
    prevSelected = selectedRowIds;
    onRowSelect && onRowSelect(getSelectedIdsFromObj(selectedRowIds, data));
  }, [onRowSelect, selectedRowIds]);
  /**
   * When sorting changes..
   */
  useEffect(() => {
    if (onSortingChange && (sortBy.length || emptySortByEnabled)) onSortingChange({ sortBy });
  }, [onSortingChange, sortBy]);

  /* When page size changes */
  useEffect(() => {
    if (onPageSizeChange) {
      if (prevPageSize !== undefined) onPageSizeChange(pageSize);
      prevPageSize = pageSize;
    }
  }, [pageSize]);

  /**
   * When page / row count changes ..
   */
  useEffect(() => {
    if (onPageChange) {
      onPageChange(pageIndex, pageSize);
    }
  }, [pageIndex]);

  /**
   * When filters change ..
   */
  useEffect(() => {
    if (onFilteringChange) onFilteringChange(filters);
  }, [filters, onFilteringChange]);

  const style = fixed ? { tableLayout: "fixed" } : undefined;

  const getRowClassName = (row) => {
    if (!onRowSelect || (onRowSelect && row.isSelected)) return undefined;
    return classes.unselectedRow;
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <TableContainer className={classes.container}>
        {isLoading && <CircularProgress style={{ position: "absolute" }} />}
        <Table stickyHeader aria-label="sticky table" {...getTableProps()} className={classes.table} style={style}>
          <TableHead
            onFilteringChange={onFilteringChange}
            onSortingChange={onSortingChange}
            headerGroups={headerGroups}
          />
          <TableBody {...getTableBodyProps()} style={{ maxHeight: "50vh" }}>
            {!isLoading &&
              rows.map((row) => {
                prepareRow(row);
                const rowProps = getRowProps ? getRowProps(row) : undefined;

                return (
                  <TableRow
                    hover
                    key={row.id}
                    {...row.getRowProps(rowProps)}
                    className={getRowClassName(row)}
                  >
                    {row.cells.map((cell) => (
                      <TableCell
                        key={`${cell.column.id}-${cell.row.id}`}
                        {...cell.getCellProps()}
                        style={cell.column.style}
                        width={cell.column.width}
                      >
                        {cell.render("Cell")}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
          </TableBody>
          {footerRow && !!rows.length && (
            <TableFooter>
              <TableRow>
                {footerRow.map((column) => (
                  <TableCell>{column.render ? column.render() : column.value || column}</TableCell>
                ))}
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
      {!data?.length && (
        <Box display="flex" justifyContent="center" fontWeight={600} mt={2}>
          {t("common.noResults")}
        </Box>
      )}
      {paginationEnabled ? (
        <Pagination
          totalCount={totalCount}
          page={controlledPageIndex ?? pageIndex}
          pageSize={pageSize}
          gotoPage={useControlledState ? onPageChange : gotoPage}
          className={classes.pagination}
          onPageSizeChange={onPageSizeChange && setPageSize}
        />
      ) : null}
    </Box>
  );
};

MaterialTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  onSortingChange: PropTypes.func,
  getRowProps: PropTypes.func,
  onFilteringChange: PropTypes.func,
  isLoading: PropTypes.bool,
  totalCount: PropTypes.number,
  defaultPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  paginationEnabled: PropTypes.bool.isRequired,
  fixed: PropTypes.bool,
  maxWidth: PropTypes.bool,
  onRowSelect: PropTypes.func,
  onRemoveLastSelection: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  disabledIds: PropTypes.arrayOf(PropTypes.string),
  footerRow: PropTypes.any,
  controlledPageIndex: PropTypes.number,
  useControlledState: PropTypes.bool,
  checkboxColumnLabel: PropTypes.string,
  checkboxAsRadio: PropTypes.bool,
  loading: PropTypes.bool,
  emptySortByEnabled: PropTypes.bool,
  initialState: PropTypes.any,
};

MaterialTable.defaultProps = {
  data: [],
  columns: [],
  onPageChange: null,
  onPageSizeChange: null,
  onSortingChange: null,
  onFilteringChange: null,
  isLoading: false,
  totalCount: 0,
  defaultPage: 0,
  defaultPageSize: 10,
  paginationEnabled: false,
  fixed: false,
  maxWidth: true,
  onRemoveLastSelection: null,
  onRowSelect: null,
  selectedIds: null,
  disabledIds: null,
  footerRow: null,
  controlledPageIndex: null,
  useControlledState: false,
  getRowProps: null,
  checkboxColumnLabel: null,
  checkboxAsRadio: false,
  loading: false,
  emptySortByEnabled: false,
  initialState: null,
};

export default MaterialTable;
