import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import AxessService from '../services/AxessService';
import OwnerService from '../services/OwnerService';
import UserService from '../services/UserService';
import { StoreState } from '../types/Common';
import { IAdminUser, IEndUser, SearchUsersDTO, UserRole } from '../types/User';
import { storeAutoSave } from '../utils/commonUtils';
import AuthStore from './AuthStore';
import ToastStore from './ToastStore';
export interface IAdminUserStore {
  state: StoreState;
  isLoading: boolean;
  users?: IAdminUser[];
  user?: IAdminUser;
  queriedUsers?: IEndUser[];
  superOwnerRole: UserRole;
  getUserList: () => void;
  getUser: (id: string) => void;
  createUser: (user: IAdminUser, cb: Function) => void;
  createOwnSkyboxCompanyUser: (user: IAdminUser, cb: Function) => void;
  updateUser: (user: IAdminUser, cb: Function) => void;
  deactivateUser: (id: string) => void;
  resetAccessCode: () => void;
  searchUsers: (data: SearchUsersDTO) => void;
  sendCodeToEmail: () => void;
  setQueriedUsers: (users?: any) => void;
  updateUserPassword: (currentPassowrd: string, newPassword: string) => void;
}

class UserModel implements IAdminUserStore {
  state: StoreState = 'Idle';
  users: IAdminUserStore['users'] = undefined;
  user: IAdminUserStore['user'] = undefined;
  queriedUsers: IAdminUserStore['queriedUsers'] = undefined;
  superOwnerRole: IAdminUserStore['superOwnerRole'] = 'SkyboxOwner';

  constructor() {
    makeObservable(this, {
      state: observable,
      users: observable,
      user: observable,
      queriedUsers: observable,
      superOwnerRole: observable,
      getUserList: action,
      getUser: action,
      createUser: action,
      createOwnSkyboxCompanyUser: action,
      updateUser: action,
      sendCodeToEmail: action,
      deactivateUser: action,
      setQueriedUsers: action,
      updateUserPassword: action,
      isLoading: computed,
      resetAccessCode: action,
    });
    const omittedFields = ['state', 'users', 'user', 'queriedUsers'];
    storeAutoSave(this, 'UserStore', omittedFields);
  }

  get isLoading() {
    return this.state === 'Loading';
  }

  setQueriedUsers = (newUsers?: any) => {
    this.queriedUsers = newUsers;
  };
  /**
   * Returns the user list
   */
  getUserList = async () => {
    this.state = 'Loading';
    try {
      const response = await UserService.getUserList();
      runInAction(() => {
        this.users = response.data.results;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
        ToastStore.showError('errors.user.getUserList');
      });
      throw error;
    }
  };

  /**
   * Returns a user by id
   */
  getUser = async (id: string) => {
    this.state = 'Loading';
    try {
      const response = await UserService.getUser(id);
      runInAction(() => {
        this.user = response.data;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  updateUserPassword = async (currentPassword: string, newPassword: string) => {
    this.state = 'Loading';
    try {
      await UserService.updateUserPassword(currentPassword, newPassword);
      ToastStore.showSuccess('successes.user.updateUserPassword');
      runInAction(() => {
        this.state = 'Success';
      });
    } catch (error) {
      ToastStore.showError('errors.user.updateUserPassword');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  /**
   * Create new SkyboxAdmin or SkyboxOwner user to any company (user by SkyboxAdmins)
   */
  createUser = async (user: IAdminUser, cb: Function) => {
    this.state = 'Loading';
    try {
      if (user.role === 'SkyboxAdmin') delete user.companyId;

      const { data: newUser }: { data: IAdminUser } =
        await UserService.createUser(user);

      if (user.companyId) {
        await OwnerService.addUserToSkyboxCompany(user.companyId, newUser.id);
      }
      ToastStore.showSuccess('successes.user.createSuccess');
      runInAction(() => {
        this.state = 'Success';
      });
    } catch (error) {
      if (cb) cb(error);
      ToastStore.showError('errors.user.createFailed');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  /**
   * Create new SkyboxOwner user to own company (used by SkybowOwners)
   */
  createOwnSkyboxCompanyUser = async (user: IAdminUser, cb: Function) => {
    this.state = 'Loading';
    try {
      await OwnerService.createOwnSkyboxCompanyUser(user);
      ToastStore.showSuccess('successes.user.createSuccess');
      runInAction(() => {
        this.state = 'Success';
      });
    } catch (error) {
      if (cb) cb(error);

      ToastStore.showError('errors.user.createFailed');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  /**
   * Update user
   */
  updateUser = async (user: IAdminUser, cb: Function) => {
    this.state = 'Loading';
    if (user.role === 'SkyboxAdmin') delete user.companyId;

    try {
      if (AuthStore.isAdmin && user.companyId) {
        await OwnerService.addUserToSkyboxCompany(user.companyId!, user.id);
      }
      await UserService.updateUser(user);
      ToastStore.showSuccess('successes.user.updateSuccess');
      runInAction(() => {
        this.state = 'Success';
      });
    } catch (error) {
      if (cb) cb(error);
      ToastStore.showError('errors.user.updateFailed');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  deactivateUser = async (id: string) => {
    this.state = 'Loading';
    try {
      await UserService.changeUserActiveStatus(id, { active: false });
      ToastStore.showSuccess('successes.user.deleteSuccess');
      runInAction(() => {
        this.state = 'Success';
      });
    } catch (error) {
      ToastStore.showError('errors.user.deleteFailed');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  searchUsers = async (data: SearchUsersDTO) => {
    this.state = 'Loading';
    try {
      const response = await UserService.searchUsers(data);

      runInAction(() => {
        this.state = 'Success';
        this.queriedUsers = response.data;
      });
    } catch (error) {
      ToastStore.showError('errors.user.searchUserFailed');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  updateSuperOwnerSettings = async (
    skyboxCompanyId: string,
    role: UserRole,
  ) => {
    if (!AuthStore.currentUser) {
      return;
    }
    this.state = 'Loading';

    try {
      await OwnerService.addUserToSkyboxCompany(
        skyboxCompanyId,
        AuthStore.currentUser.id,
      );

      runInAction(() => {
        ToastStore.showSuccess('successes.user.updateSuperOwnerSettings');
        this.state = 'Success';
      });

      setTimeout(() => {
        window.location.href = window.location.origin;
        this.superOwnerRole = role;
      }, 1000);
    } catch (error) {
      ToastStore.showError('errors.user.updateSuperOwnerSettings');
    }
  };

  resetAccessCode = async () => {
    this.state = 'Loading';
    try {
      await AxessService.resetAccessCode();
      await AuthStore.getCurrentUser();

      runInAction(() => {
        this.state = 'Success';
        ToastStore.showSuccess('successes.user.resetAccessCode');
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  sendCodeToEmail = async () => {
    this.state = 'Loading';
    try {
      await AxessService.sendAccessCodeToEmail();
      runInAction(() => {
        this.state = 'Success';
        ToastStore.showSuccess('successes.user.sendCodeToEmail');
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };
}

const UserStore = new UserModel();

export default UserStore;
