import { computed, makeObservable, observable, runInAction } from 'mobx';
import OwnerService from '../services/OwnerService';
import { StoreState } from '../types/Common';
import AuthStore from './AuthStore';
import ToastStore from './ToastStore';

export interface ISettingsStore {
  state: StoreState;
  isLoading: boolean;
  myCompanyData?: {
    id: string;
    name: string;
    invoicingEnabled: boolean;
    invoicingFee: number;
    invoicingEmail: string;
    ownerCustomers?: { slug: string }[];
  };
  updateMyCompanyData: (data: any) => void;
  getMyCompanyData: () => void;
  reset: () => void;
}

class SettingsModel implements ISettingsStore {
  state: StoreState = 'Idle';

  myCompanyData: ISettingsStore['myCompanyData'] = undefined;

  constructor() {
    makeObservable(this, {
      state: observable,
      isLoading: computed,
      myCompanyData: observable,
    });
  }

  get isLoading() {
    return this.state === 'Loading';
  }

  reset = () => {
    this.state = 'Idle';
    this.myCompanyData = undefined;
  };

  /**
   * Loads settings
   */
  getMyCompanyData = async () => {
    if (!AuthStore.isLoggedIn) return;

    this.state = 'Loading';
    try {
      const res = (await OwnerService.getMyCompanyData()) as any;
      runInAction(() => {
        this.myCompanyData = res.data;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        ToastStore.showError('errors.settings.getMyCompanyDataFailed');
        this.state = 'Error';
      });
      throw error;
    }
  };

  /**
   * Loads settings
   */
  updateMyCompanyData = async (data: any) => {
    this.state = 'Loading';
    try {
      const res = (await OwnerService.updateMyCompanyData(data)) as any;
      runInAction(() => {
        this.myCompanyData = res.data;
        this.state = 'Success';
        ToastStore.showSuccess('successes.settings.updateSuccess');
      });
    } catch (error) {
      runInAction(() => {
        ToastStore.showError('errors.common.save');
        this.state = 'Error';
      });
      throw error;
    }
  };
}

const SettingsStore = new SettingsModel();

export default SettingsStore;
