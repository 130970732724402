/* eslint-disable max-len */
/* eslint-disable implicit-arrow-linebreak */
import AuthStore from '../stores/AuthStore';
import { IAdminUser, IEndUser, SearchUsersDTO } from '../types/User';
import { api, getAuthConfig, getPath } from './api';

export const API_PATHS = Object.freeze({
  all: '/customer/{customerId}/admin-user?roles=',
  owners: '/customer/{customerId}/admin-user?roles=SkyboxOwner',
  get: '/customer/{customerId}/admin-user/{itemId}',
  create: '/customer/{customerId}/admin-user',
  update: '/customer/{customerId}/admin-user/{itemId}',
  activeStatus: '/customer/{customerId}/admin-user/{itemId}/active',
  search: '/customer/{customerId}/user/search',
  createEndUser: '/customer/{customerId}/user',
  password: '/customer/{customerId}/admin-user/me/password',
});

class UserServiceModel {
  /**
   * User (Admins)
   */
  getUserList = async (skip?: number, take?: number) => {
    const { all, owners } = API_PATHS;
    let path: string = owners;

    if (AuthStore.isAdmin) {
      const skyboxRoles = [
        'SkyboxAdmin',
        'SkyboxOwner',
        'SuperOwner',
        'Janitor',
      ];
      const roles = skyboxRoles;
      path = `${all}${roles.join(',')}`;
    }

    return api.get(getPath(path), getAuthConfig(false, { skip, take }));
  };

  getUser = async (id: string) =>
    api.get(getPath(API_PATHS.get, id), getAuthConfig());

  createUser = async (data: IAdminUser) =>
    api.post(getPath(API_PATHS.create), data);

  updateUser = async (data: IAdminUser) =>
    api.put(getPath(API_PATHS.update, data.id), data, getAuthConfig());

  changeUserActiveStatus = async (id: string, params: { active: boolean }) =>
    api.put(getPath(API_PATHS.activeStatus, id), params, getAuthConfig());

  updateUserPassword = async (currentPassword: string, newPassword: string) =>
    api.put(
      getPath(API_PATHS.password),
      { currentPassword, newPassword },
      getAuthConfig(),
    );

  /**
   * User (non-admins)
   */
  searchUsers = async (data: SearchUsersDTO) =>
    api.post(getPath(API_PATHS.search), data);

  createEndUser = async (data: IEndUser) =>
    api.post(getPath(API_PATHS.createEndUser), data, getAuthConfig());
}

const UserService = new UserServiceModel();

export default UserService;
