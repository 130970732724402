import { Box, makeStyles, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import colors from '../../styles/colors';
import theme from '../../styles/theme';
import { DropdownItem, DropdownMenu } from '../DropdownMenu/DropdownMenu';
import { Button } from '../FormControls/Button';

export interface FormHeaderProps {
  onCancel?: any;
  title: string;
  loading?: boolean;
  hideSubmit?: boolean;
  submitDisabled?: boolean;
  onSubmit?: () => void;
  dropdownItems?: DropdownItem[];
}

const useStyles = makeStyles({
  formHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    borderBottom: `1px solid ${colors.stroke}`,
  },
  formHeaderButtons: {
    display: 'flex',
    flex: '1 1 auto',
    width: 'auto',
    minWidth: 0,
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& button': {
      marginLeft: theme.spacing(2),
    },
  },
  title: {
    color: colors.text.body1,
  },
});

export const FormHeader: FC<FormHeaderProps> = observer(
  ({
    onCancel,
    title,
    loading,
    children,
    hideSubmit,
    submitDisabled,
    onSubmit,
    dropdownItems,
  }) => {
    const classes = useStyles();

    return (
      <Box className={classes.formHeader}>
        <Typography className={classes.title}>{title}</Typography>
        <Box className={classes.formHeaderButtons}>
          {onCancel && (
            <Button
              id="cancelButton"
              variant="outlined"
              t="common.cancel"
              loading={loading}
              onClick={onCancel}
            />
          )}
          {!hideSubmit && (
            <Button
              id="saveButton"
              type={onSubmit ? 'button' : 'submit'}
              t="common.save"
              color="secondary"
              onClick={onSubmit}
              loading={loading}
              disabled={submitDisabled}
            />
          )}
          {!!dropdownItems?.length && <DropdownMenu items={dropdownItems} />}
        </Box>
        {children}
      </Box>
    );
  },
);
