import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import {
  faArrowsAltV,
  faExclamationCircle,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export interface IconTextButtonProps {
  onClick: any;
  label: string;
  type: 'delete' | 'add' | 'sort' | 'info';
  id?: string;
  variant?: 'outlined';
}

export const IconTextButton: FC<IconTextButtonProps> = observer(
  ({ type, label, onClick, id, variant }) => {
    const { t } = useTranslation();
    const _id = id || 'delete-button';

    const getIcon = () => {
      switch (type) {
        case 'info':
          return faExclamationCircle;
        case 'sort':
          return faArrowsAltV;
        case 'delete':
          return faTrashAlt;
        case 'add':
          return faPlus;
        default:
          return null;
      }
    };
    return (
      <Button
        id={_id}
        variant={variant}
        startIcon={
          <FontAwesomeIcon
            icon={getIcon() || faPlus}
            style={{ fontSize: '16px' }}
          />
        }
        onClick={onClick}
      >
        {t(label)}
      </Button>
    );
  },
);
