import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import colors from '../../styles/colors';
import { InvalidCalendarSlotWarning } from '../../types/Calendar';
import { ColorBox } from '../ColorBox/ColorBox';

export interface ReservationCalendarWarningProps {
  type?: InvalidCalendarSlotWarning;
}

const useStyles = makeStyles({
  warningText: {
    fontWeight: 600,
    textTransform: 'uppercase',
  },
});
export const ReservationCalendarWarning: React.FC<
  ReservationCalendarWarningProps
> = ({ type, children }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <ColorBox color="red" mb={3} display="flex" alignItems="center" whiteText>
      <FontAwesomeIcon icon={faExclamationTriangle} color={colors.red} />
      {!children && (
        <>
          <Box mx={1}>
            <Typography className={classes.warningText}>
              {t('calendar.warnings.warning')}:
            </Typography>
          </Box>
          <Typography>{t(`calendar.warnings.warning_${type}`)}</Typography>
        </>
      )}
      {children && <Box ml={1}>{children} </Box>}
    </ColorBox>
  );
};
