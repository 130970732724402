/* eslint-disable react-hooks/exhaustive-deps */

import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Mail } from '@material-ui/icons';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../stores/index';
import colors from '../../styles/colors';
import { IBigCalendarReservation } from '../../types/Calendar';
import { IAdminUser } from '../../types/User';
import { ToggleableTextField } from '../TogglableTextField';

interface GuestListProps {
  validate?: any;
  organizer?: IAdminUser;
  form: any;
  name: string;
  values: any;
  reservation?: IBigCalendarReservation;
}

const useStyles = makeStyles({
  container: {},
  list: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    overflowY: 'auto',
    maxHeight: '30vh',
  },
  listItem: {
    borderColor: colors.stroke,
    borderRadius: 2,
    minHeight: 60,
    alignItems: 'center',
    border: '1px solid',
    display: 'flex',
  },
  userContent: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  removeButton: {
    '& svg': {
      maxHeight: 16,
    },
  },
});

export const GuestList: FC<GuestListProps> = observer(
  ({ validate, values, organizer, form, name, reservation }) => {
    const [count, setCount] = useState(0);
    const classes = useStyles();
    const { t } = useTranslation();
    const {
      calendarStore: { resendAccessCodes },
      authStore: { isOwner },
    } = useStores();

    useEffect(() => {
      if (!values[name]) {
        setCount(0);
        return;
      }
      setCount(Object.keys(values[name]).length);
    }, [values]);

    const rows = useMemo(() => {
      return values[name] && Object.values(values[name]);
    }, [count]);

    const onAddGuestClick = () => {
      const newValue = { ...values[name], ...{ [`guest${count || 0}`]: '' } };
      form.change(name, newValue);
    };

    const onRemoveClick = (index: number) => {
      const newValue = { ...values[name], ...{ [`guest${index}`]: undefined } };
      form.change(name, newValue);
    };

    const onResendCodesClick = () => {
      resendAccessCodes(reservation!.id);
    };

    return (
      <Box className={classes.container}>
        <Box
          my={3}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h3">
            {t('calendar.reservationModal.guests')}
          </Typography>
          {isOwner && (
            <Box display="flex" alignItems="center">
              {reservation?.axessEventId && (
                <Box mr={1}>
                  <Button variant="text" onClick={onResendCodesClick}>
                    <Mail />
                    <Box pr={1} />
                    {t('calendar.reservationModal.resendCodes')}
                  </Button>
                </Box>
              )}
              <Button variant="outlined" onClick={onAddGuestClick}>
                <FontAwesomeIcon icon={faPlus} />
                <Box mr={1} />
                <Typography>
                  {t('calendar.reservationModal.addGuest')}
                </Typography>
              </Button>
            </Box>
          )}
        </Box>
        <Box component="ul" className={classes.list}>
          <Box p={1} mb={1} component="li" className={classes.listItem}>
            <Box ml={1} mr={2}>
              <FontAwesomeIcon icon={faUser} />
            </Box>
            <Box>
              {`${organizer?.firstName} ${organizer?.lastName} ${t(
                'calendar.reservationModal.currentUserStatus',
              )}`}
            </Box>
          </Box>

          {rows &&
            rows.map((_item: string, index: number) => {
              const fieldName = `${name}.guest${index}`;
              if (Object.values(values[name])[index] === undefined) return null;
              return (
                <Box
                  p={1}
                  mb={1}
                  component="li"
                  className={classes.listItem}
                  key={fieldName}
                >
                  <Box ml={1} mr={2}>
                    <FontAwesomeIcon icon={faUser} />
                  </Box>
                  <Box className={classes.userContent}>
                    <ToggleableTextField
                      width="100%"
                      hideEditIcon
                      disabled={!isOwner}
                      validate={validate}
                      name={fieldName}
                      placeholder={t('calendar.reservationModal.enterEmail')}
                    />
                  </Box>
                  {isOwner && (
                    <IconButton
                      onClick={() => onRemoveClick(index)}
                      className={classes.removeButton}
                    >
                      <FontAwesomeIcon
                        color={colors.text.body2}
                        icon={faTrash}
                      />
                    </IconButton>
                  )}
                </Box>
              );
            })}
        </Box>
      </Box>
    );
  },
);
