import { Box, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { makeRequired, makeValidate, Switches, TextField } from 'mui-rff';
import { FC, useMemo } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { CurrencyField } from '../../components/CurrencyField';
import { FormHeader } from '../../components/FormHeader/FormHeader';
import { DEFAULT_INVOICING_FEE_VAT } from '../../constants/siteSettings';
import { useStores } from '../../stores/index';
import { IOwner } from '../../types/Owner';
import { IAdminUser } from '../../types/User';
import { Validator } from '../../utils/validation';
import { OwnerUsersForm } from './OwnerUsersForm';

interface OwnerFormProps {
  initialValues?: IOwner;
  ownerUsers?: IAdminUser[];
  isLoading: boolean;
  onCancel: () => void;
  onSubmit: (values: any, form: any, callback: any) => void;
  onAddOwnerUser?: (user: IAdminUser) => void;
  onDeleteOwnerUser?: (id: string) => void;
}

interface WrappedOwnerFormProps extends OwnerFormProps {
  isAdmin?: boolean;
}

const schema = Validator.object().shape({
  name: Validator.string().max(255).required(),
  invoicingEnabled: Validator.boolean(),
  companyId: Validator.string(),
  billingAddress: Validator.string(),
  invoicingEmail: Validator.string().email(),
  eInvoiceAddress: Validator.string(),
  operator: Validator.string(),
  brokerIdentifier: Validator.string(),
  invoicingFee: Validator.number(),
  hideFromPublic: Validator.boolean(),
});

const ownerFormValidate = makeValidate(schema);
const ownerFormRequired = makeRequired(schema);

const WrappedOwnerForm: FC<WrappedOwnerFormProps> = observer(
  ({
    initialValues,
    isAdmin,
    ownerUsers,
    isLoading,
    onSubmit,
    onCancel,
    onAddOwnerUser,
    onDeleteOwnerUser,
  }) => {
    const { t } = useTranslation();

    const invoicingFields = useMemo(() => {
      let fields = [
        {
          id: 'companyId',
        },
        {
          id: 'billingAddress',
        },
        {
          id: 'invoicingEmail',
        },
        {
          id: 'eInvoiceAddress',
        },
        {
          id: 'operator',
        },
        {
          id: 'brokerIdentifier',
        },
      ] as any;

      if (isAdmin) {
        fields.unshift({
          id: 'invoicingEnabled',
          render: () => (
            <Switches
              name="invoicingEnabled"
              data={{ label: t('owner.invoicingEnabled'), value: true }}
            />
          ),
        });

        fields.push({
          id: 'invoicingFee',
          render: () => {
            return (
              <CurrencyField
                label={t('owner.invoicingFee')}
                name="invoicingFee"
                helperText={t('owner.invoicingFeeInformation', {
                  vat: DEFAULT_INVOICING_FEE_VAT,
                })}
              />
            );
          },
        });
      }

      fields = fields.map((field: any) => {
        return { ...field, ...{ label: t(`owner.${field.id}`) } };
      });

      return fields;
    }, [isAdmin, t]);

    return (
      <Form
        initialValues={initialValues}
        validate={ownerFormValidate}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <FormHeader
                loading={submitting || isLoading}
                title={!initialValues ? t('owner.addNewOwner') : ''}
                onCancel={onCancel}
              />

              <Box p={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h2" paragraph>
                      {t('owner.companyInformation')}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="name"
                      name="name"
                      label={t('common.companyName')}
                      required={ownerFormRequired.name}
                    />
                    <Grid item xs={4}></Grid>
                  </Grid>
                </Grid>
                {isAdmin && (
                  <Box mt={2}>
                    <Switches
                      name="hideFromPublic"
                      data={{ label: t('owner.hideFromPublic'), value: true }}
                    />
                  </Box>
                )}
                <Box mt={3}>
                  <Typography variant="h2" paragraph>
                    {t('owner.invoicingInformation')}
                  </Typography>
                  <Grid container spacing={3}>
                    {invoicingFields?.map((field: any) => (
                      <Grid item xs={4}>
                        {field.render && field.render()}
                        {!field.render && (
                          <TextField name={field.id} label={field.label} />
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </Box>
                {initialValues && isAdmin && (
                  <Box mt={3}>
                    <OwnerUsersForm
                      ownerUsers={ownerUsers}
                      isLoading={isLoading}
                      onAddOwnerUser={onAddOwnerUser!}
                      onDeleteOwnerUser={onDeleteOwnerUser!}
                    />
                  </Box>
                )}
              </Box>
            </form>
          );
        }}
      />
    );
  },
);

export const OwnerForm: FC<OwnerFormProps> = observer((props) => {
  const {
    authStore: { isAdmin },
  } = useStores();
  return <WrappedOwnerForm isAdmin={isAdmin} {...props} />;
});
