import { Box, makeStyles, Typography } from '@material-ui/core';
import uniq from 'lodash.uniq';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { SKYBOX_SALES_DEFAULT_CHANNEL } from '../../../../constants/siteSettings';
import { useStores } from '../../../../stores';
import colors from '../../../../styles/colors';
import { ISkyboxWithEventSkybox, SkyboxStatus } from '../../../../types/Skybox';
import { getSkyboxStatus } from '../../../../utils/commonUtils';
import { getPrettyDate } from '../../../../utils/i18nUtils';
import { BoxRadioGroup } from '../../../BoxRadioGroup/BoxRadioGroup';
import { SkyboxStatusText } from '../../../SkyboxStatusText';
import { CommonModal, CommonModalProps } from '../../CommonModal';
import { SkyboxSettingsCrossUse } from './CrossUse';
import { SkyboxSettingsNotForSale } from './NotForSale';
import { SkyboxSettingsRerent } from './Rerent';
import { SelfReservationContactDetailsForm } from './SelfReservationContactDetails/SelfReservationContactDetailsForm';
import { SelfReservationContactDetailsParams } from './SelfReservationContactDetails/SelfReservationContactDetailsParams';
import { getValidReservationContactDetailsFields } from './SelfReservationContactDetails/SelfReservationContactDetailsSchema';
import { SkyboxSettingsModalFooter } from './SkyboxSettingsModalFooter';
import { SkyboxSettingsTargetedRental } from './TargetedRental';

export interface SkyboxSettingsModalProps
  extends Omit<CommonModalProps, 'children' | 'type' | 'onClose'> {
  skybox?: ISkyboxWithEventSkybox;
  onClose: any;
}

const useStyles = makeStyles({
  eventTitle: {
    fontSize: '16px',
    color: colors.text.body2,
  },
});

const RERENT_FOR_PREFIX = 'rerentFor';
const rerentForType = (slug: string) => `${RERENT_FOR_PREFIX}${slug}`;
const rerentForSlug = (type: string) =>
  type.substring(RERENT_FOR_PREFIX.length);

// TODO: Returns sales channel names for skyboxes, without needing to fetch separate customer data
// since it is not allowed anymore
const slugToName = (slug?: string) => {
  if (slug === 'ilves') {
    return 'Ilves';
  }
  if (slug === 'nokiaarena') {
    return 'Nokia Arena';
  }

  return 'Name missing';
};

const USAGE_TYPES = [
  'blank',
  'not_for_sale',
  'rerent',
  RERENT_FOR_PREFIX,
  'targeted_rental',
  'crossUse',
] as const;

export const SkyboxSettingsModal: FC<SkyboxSettingsModalProps> = observer(
  ({ open, skybox, onClose }) => {
    const { t } = useTranslation();
    const [initialValues, setInitialValues] = useState<any>();
    const [usage, setUsage] = useState<
      SkyboxStatus | `${typeof RERENT_FOR_PREFIX}${string}` | ''
    >('');
    const {
      eventStore: { event },
      skyboxStore: { resetMySkybox, reserveMySkybox },
      authStore: { isAdmin, isOwner },
      settingsStore: { myCompanyData },
    } = useStores();
    const classes = useStyles();
    const [selfReservationContactDetails, setSelfReservationContactDetails] =
      useState<SelfReservationContactDetailsParams>({
        contactName: skybox?.eventSkybox?.contactName,
        contactPhone: skybox?.eventSkybox?.contactPhone,
        contactEmail: skybox?.eventSkybox?.contactEmail,
      });

    const channels = useMemo(() => {
      const myCustomers = myCompanyData?.ownerCustomers
        ?.map(({ slug }) => slug)
        .filter((slug) => slug !== SKYBOX_SALES_DEFAULT_CHANNEL);
      return myCustomers?.length
        ? uniq([...myCustomers, SKYBOX_SALES_DEFAULT_CHANNEL])
        : undefined;
    }, [myCompanyData]);
    const isRented = skybox?.rented;

    useEffect(() => {
      if (!initialValues && skybox) {
        const initialStatus = isAdmin ? 'crossUse' : getSkyboxStatus(skybox);
        setInitialValues({
          usage:
            initialStatus === 'rerent' &&
            channels &&
            skybox.eventSkybox.eventSkyboxCustomers.length === 1
              ? rerentForType(
                  skybox.eventSkybox.eventSkyboxCustomers[0]!.customer.slug,
                )
              : initialStatus,
        });
      }
    }, [skybox, initialValues, isAdmin, event, channels]);

    const usageTypeOptions = useMemo(() => {
      const isDisabled = (
        type: typeof USAGE_TYPES extends Iterable<infer U> ? U : never,
      ) => {
        if (
          type === 'crossUse' &&
          (!skybox?.secondaryOwners?.length ||
            (isOwner && skybox.ownerCompanyId !== myCompanyData!.id))
        ) {
          return true;
        }
        if (isRented) return true;
        if (isAdmin && type !== 'crossUse') {
          return true;
        }
        return false;
      };

      return USAGE_TYPES.reduce<
        { value: string; label: string; disabled: boolean }[]
      >((types, type) => {
        const disabled = isDisabled(type);

        if (type === RERENT_FOR_PREFIX) {
          if (channels?.length) {
            return [
              ...types,
              ...channels.map((slug) => ({
                value: rerentForType(slug),
                label: t('events.skyboxSettingsModal.usageTypes.rerentForOne', {
                  target: slugToName(slug),
                }),
                disabled,
              })),
            ];
          }
          return [...types];
        }

        return [
          ...types,
          {
            value: type,
            label:
              type === 'rerent'
                ? t('events.skyboxSettingsModal.usageTypes.rerentForMulti', {
                    target: (channels ?? [SKYBOX_SALES_DEFAULT_CHANNEL]).map(
                      (slug) => slugToName(slug),
                    ),
                  })
                : t(`events.skyboxSettingsModal.usageTypes.${type}`),
            disabled,
          },
        ];
      }, []);
    }, [skybox, isOwner, myCompanyData, isRented, isAdmin, channels, t]);

    const handleClose = () => {
      onClose();
    };

    const onBackClick = () => {
      setUsage('');
    };

    if (!skybox) return null;

    const onMainSubmit = async (values: any) => {
      const eventId = event!.id;
      const eventSeriesId = event!.eventSeriesId;
      const skyboxId = skybox!.id;

      if (values.usage === 'blank') {
        await resetMySkybox({ eventId, skyboxId });
        return handleClose();
      } else if (values.usage === 'not_for_sale') {
        await reserveMySkybox({
          eventSeriesId,
          eventId,
          skyboxId,
          ...getValidReservationContactDetailsFields(
            selfReservationContactDetails,
          ),
        });
      }

      setUsage(values.usage);
    };

    const Main = (
      <Form
        initialValues={initialValues}
        onSubmit={onMainSubmit}
        render={({ handleSubmit, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Typography variant="h1">{skybox.name}</Typography>
              <Box mt={2} />
              <Typography className={classes.eventTitle}>
                {event?.title}{' '}
                {getPrettyDate(event?.date, true, t('common.clockAbbr'))}
              </Typography>
              <Box mt={3} />

              <SkyboxStatusText skybox={skybox} showInBox />

              {!isRented && (
                <Box mb={2}>
                  <BoxRadioGroup
                    label={t('events.skyboxSettingsModal.radioLabel')}
                    options={usageTypeOptions}
                    name="usage"
                  />
                  {values.usage === 'not_for_sale' && (
                    <SelfReservationContactDetailsForm
                      contactDetails={selfReservationContactDetails}
                      setContactDetails={setSelfReservationContactDetails}
                    />
                  )}
                </Box>
              )}
              <SkyboxSettingsModalFooter
                onCancel={onClose}
                confirmText={t('events.skyboxSettingsModal.confirm')}
                hideConfirm={isRented}
                cancelText={isRented ? 'common.close' : undefined}
              />
            </form>
          );
        }}
      />
    );

    return (
      <CommonModal open={open} maxWidth={700}>
        <Box>
          <Box my={3}>
            {usage === '' && Main}
            {usage === 'not_for_sale' && (
              <SkyboxSettingsNotForSale
                onCancel={onBackClick}
                skybox={skybox}
                onConfirm={() => onClose()}
              />
            )}
            {(usage as string).startsWith('rerent') && (
              <SkyboxSettingsRerent
                onCancel={onBackClick}
                skybox={skybox}
                onConfirm={() => onClose()}
                channels={
                  usage === 'rerent'
                    ? channels ?? [SKYBOX_SALES_DEFAULT_CHANNEL]
                    : [rerentForSlug(usage as string)]
                }
              />
            )}
            {usage === 'targeted_rental' && (
              <SkyboxSettingsTargetedRental
                onCancel={onBackClick}
                onClose={onClose}
                skybox={skybox}
              />
            )}
            {usage === 'crossUse' && (
              <SkyboxSettingsCrossUse
                onCancel={onBackClick}
                onConfirm={() => onClose()}
                skybox={skybox}
              />
            )}
          </Box>
        </Box>
      </CommonModal>
    );
  },
);
