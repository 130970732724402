import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import ToastStore from '../stores/ToastStore';

const isCapturableStatus = (status: number) => {
  return status === 429 || status >= 500;
};

const standardErrorHandler = (error: AxiosError) => {
  if (error.response) {
    const {
      response: { status },
    } = error;

    switch (status) {
      case 429:
        ToastStore.showError('errors.http.429');
        break;
      default:
        break;
    }
  }
  // Capture specific response errors
  if (error.response?.status && isCapturableStatus(error.response.status)) {
    Sentry.captureException(error);
  }

  // Capture when no response is received
  if (!error.response || error.message?.includes('timeout')) {
    Sentry.captureException(error);
  }

  throw error;
};

export default standardErrorHandler;
