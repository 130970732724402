import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import ToastStore from './ToastStore';

import OrderService from '../services/OrderService';
import { StoreState } from '../types/Common';
import {
  GetOrdersDTO,
  IOrder,
  ResendOrderConfirmationDTO,
} from '../types/Order';
import { storeAutoSave } from '../utils/commonUtils';

export interface IOrderStore {
  isLoading: boolean;
  order?: IOrder;
  orderCount?: number;
  orders?: IOrder[];
  allOrders?: IOrder[];
  queryParams: any;
  state: StoreState;
  getOrder: (purchaseId: string) => void;
  getOrders: (params: GetOrdersDTO) => void;
  resendOrderConfirmation: (data: ResendOrderConfirmationDTO) => void;
  reset: () => void;
}

class OrderModel implements IOrderStore {
  order: IOrderStore['order'] = undefined;

  orderCount: IOrderStore['orderCount'] = 0;

  // Paginated
  orders: IOrderStore['orders'] = undefined;

  // Non-paginated, used with CSV:s
  allOrders: IOrderStore['allOrders'] = undefined;

  queryParams: IOrderStore['queryParams'] = undefined;

  state: StoreState = 'Idle';

  constructor() {
    makeObservable(this, {
      getOrder: action,
      getOrders: action,
      reset: action,
      isLoading: computed,
      order: observable,
      queryParams: observable,
      orderCount: observable,
      orders: observable,
      state: observable,
      resendOrderConfirmation: action,
    });
    const omittedFields = ['isChildOrder', 'state'];
    storeAutoSave(this, 'OrderStore', omittedFields);
  }

  get isLoading() {
    return this.state === 'Loading';
  }

  reset = () => {
    runInAction(() => {
      this.orders = undefined;
      this.order = undefined;
      this.state = 'Idle';
      this.orderCount = 0;
    });
  };

  /**
   * Loads orders
   */
  getOrders = async (params?: GetOrdersDTO) => {
    this.state = 'Loading';
    try {
      const res = await OrderService.getOrders(params);
      runInAction(() => {
        if (params) {
          if (!params.skipSave) {
            this.queryParams = params;
            this.orders = res.data?.results;
            this.orderCount = res.data?.totalCount;
          }
        } else {
          this.allOrders = res.data?.results;
        }
        this.state = 'Success';
      });
      return res;
    } catch (error) {
      runInAction(() => {
        ToastStore.showError('errors.order.getOrders');
        this.state = 'Error';
      });
      throw error;
    }
  };

  /**
   * Get order
   */
  getOrder = async (purchaseId?: string) => {
    if (!purchaseId) {
      console.error('Cannot get order: missing cartId');
      return;
    }
    this.state = 'Loading';
    try {
      const results = await OrderService.getOrder(purchaseId!);
      runInAction(() => {
        this.state = 'Success';
        this.order = results.data.result;
      });
    } catch (error) {
      ToastStore.showError('errors.order.getOrder');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  resendOrderConfirmation = async (data: ResendOrderConfirmationDTO) => {
    this.state = 'Loading';
    try {
      await OrderService.resendOrderConfirmation(data);
      runInAction(() => {
        this.state = 'Success';
        ToastStore.showSuccess('successes.order.resendOrderConfirmation');
      });
    } catch (error) {
      ToastStore.showError('errors.order.resendOrderConfirmation');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };
}

const OrderStore = new OrderModel();

export default OrderStore;
