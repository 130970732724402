import { Box, Typography as T } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/FormControls';
import { Layout } from '../../components/Layout';
import { CellRenderers } from '../../components/MaterialTable/CellRenderers';
import MaterialTable from '../../components/MaterialTable/MaterialTable';
import { useStore } from '../../stores';

export const OwnersScreen: React.FC = observer(() => {
  const { ownerListPage, getOwnerList } = useStore('ownerStore');
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    getOwnerList();
  }, [getOwnerList]);

  const onAddClick = () => {
    history.push('/owner/add-new');
  };

  const addButton = (
    <Box>
      <Button id="addOwnerButton" startIcon="+" onClick={onAddClick}>
        {t('owner.addNewOwner')}
      </Button>
    </Box>
  );

  const COLUMNS = [
    {
      accessor: 'name',
      Header: t('common.owner'),
      Cell: CellRenderers.link((row) => `/owner/${row.id}`),
    },
    {
      accessor: 'skyboxes',
      Header: t('owner.numberOfSkyboxes'),
      Cell: ({ cell }: any) => <T>{cell.value?.length || 0}</T>,
    },
    {
      accessor: 'users',
      Header: t('owner.numberOfUsers'),
      Cell: ({ cell }: any) => <T>{cell.value?.length || 0}</T>,
    },
  ];

  return (
    <Layout headerText={t('sidemenu.owners')}>
      <Box p={3}>
        <Box
          mb={3}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <T color="textSecondary">
            {t('owner.ownerCount', { count: (ownerListPage || []).length })}
          </T>
          {addButton}
        </Box>
        <MaterialTable
          columns={COLUMNS}
          data={ownerListPage || []}
          getRowTestId={(rowData) => `owners-row__${rowData.name}`}
        />
      </Box>
    </Layout>
  );
});
