/* eslint-disable max-len */
/* eslint-disable implicit-arrow-linebreak */
import { IOwner } from '../types/Owner';
import { IAdminUser } from '../types/User';
import { api, getAuthConfig, getPath } from './api';

const API_PATHS = Object.freeze({
  all: '/customer/{customerId}/skybox-company',
  get: '/customer/{customerId}/skybox-company/{itemId}',
  create: '/customer/{customerId}/skybox-company',
  update: '/customer/{customerId}/skybox-company/{itemId}',
  delete: '/customer/{customerId}/skybox-company/{itemId}',
  addUser:
    '/customer/{customerId}/skybox-company/{itemId}/add/{secondaryItemId}',
  deleteUser:
    '/customer/{customerId}/skybox-company/{itemId}/remove/{secondaryItemId}',
  createOwnUser: '/customer/{customerId}/skybox-company/own/createUser',
  my: '/customer/{customerId}/skybox-company/my',
});

class OwnerServiceModel {
  /**
   * Owner (Businesses)
   */
  getOwnerList = async () => api.get(getPath(API_PATHS.all), getAuthConfig());

  getOwner = async (id: string) => {
    try {
      const response = await api.get(
        getPath(API_PATHS.get, id),
        getAuthConfig(),
      );
      const owner = response.data;
      return owner;
    } catch (error) {
      throw error;
    }
  };
  createOwner = async (data: IOwner) =>
    api.post(getPath(API_PATHS.create), data);

  updateOwner = async (data: IOwner) => {
    return api.put(getPath(API_PATHS.update, data.id), data, getAuthConfig());
  };
  deleteOwner = async (id: string) =>
    api.delete(getPath(API_PATHS.delete, id), getAuthConfig());

  createOwnSkyboxCompanyUser = async (data: IAdminUser) =>
    api.post(getPath(API_PATHS.createOwnUser), data);

  addUserToSkyboxCompany = async (id: string, userId: string) =>
    api.put(getPath(API_PATHS.addUser, id, userId), getAuthConfig());

  deleteUserFromSkyboxCompany = async (id: string, userId: string) =>
    api.put(getPath(API_PATHS.deleteUser, id, userId), getAuthConfig());

  getMyCompanyData = async () =>
    api.get(getPath(API_PATHS.my), getAuthConfig());

  updateMyCompanyData = async (data: any) =>
    api.put(getPath(API_PATHS.my), data, getAuthConfig());
}

const OwnerService = new OwnerServiceModel();

export default OwnerService;
