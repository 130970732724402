/* eslint-disable max-len */
/* eslint-disable implicit-arrow-linebreak */
import { CUSTOMER_ID } from '../constants/siteSettings';
import { LivexDocumentType } from '../types/Common';
import { ICustomerWithGeneratedValues } from '../types/Customer';
import { api, getAuthConfig, getPath } from './api';

export const API_PATHS = Object.freeze({
  customerBySlug: '/customer/slug/{itemId}',
  getDocument: '/customer/{customerId}/document/{itemId}/{secondaryItemId}',
  settings: '/customer/settings/{customerId}',
  customer: '/customer/{itemId}',
});

const prepareGetCustomerData = (data: ICustomerWithGeneratedValues) => {
  const newData = { ...data };
  newData.eventQueryType = data.eventQueryIds.houseCodes?.length
    ? 'houseCodes'
    : 'ownerClientIds';
  newData.ownerClientIds = data.eventQueryIds.ownerClientIds.join(',');
  newData.houseCodes = data.eventQueryIds.houseCodes.join(',');
  return newData;
};

class CustomerServiceModel {
  getCustomer = async (slug?: string) => {
    const res = await api.get(
      getPath(API_PATHS.customerBySlug, slug ?? CUSTOMER_ID),
      getAuthConfig(),
    );
    return prepareGetCustomerData(res?.data);
  };

  getDocument = async (type: LivexDocumentType, lang: string) =>
    await api.get(
      getPath(API_PATHS.getDocument, lang, type as any),
      getAuthConfig(),
    );
}

const CustomerService = new CustomerServiceModel();

export default CustomerService;
