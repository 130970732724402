import { v4 as uuid } from 'uuid';
import { IToast } from '../types/Common';

class Toast implements IToast {
  msg: string;
  values?: any;
  type: IToast['type'];
  index: number;
  id: string;

  constructor(msg: string, type: IToast['type'], index: number, values?: any) {
    this.id = uuid();
    this.msg = msg;
    this.values = values;
    this.type = type;
    this.index = index;
  }
}

export default Toast;
