import { Box, Button, Typography as T, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UsersLayout } from '../../components/Layout/hocs/UsersLayout';
import { Link } from '../../components/Link';
import { CellRenderers } from '../../components/MaterialTable/CellRenderers';
import MaterialTable from '../../components/MaterialTable/MaterialTable';
import { EditUserModal } from '../../components/Modals';
import { CommonModal } from '../../components/Modals/CommonModal';
import { useStores } from '../../stores';
import { UserForm } from './UserForm';

export const UsersScreen: React.FC = observer(() => {
  const {
    userStore: {
      isLoading,
      users,
      user,
      getUserList,
      getUser,
      createUser,
      updateUser,
      deactivateUser,
      createOwnSkyboxCompanyUser,
    },
    ownerStore: { ownerListPage, getOwnerList },
    authStore: { isAdmin, isOwner, isManager },
  } = useStores();

  const [initialized, setInitialized] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!initialized) {
      getUserList();
      if (isAdmin) {
        getOwnerList();
      }
      setInitialized(true);
    }
  }, [getUserList, getOwnerList, isAdmin, initialized, setInitialized]);

  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [editUserModalOpen, setEditUserModalOpen] = useState(false);
  const [deactivateUserModalOpen, setDeactivateUserModalOpen] = useState(false);

  const handleAddClick = () => {
    setAddUserModalOpen(true);
  };

  const handledeactivateClick = useCallback(
    (id: string) => () => {
      getUser(id);
      setDeactivateUserModalOpen(true);
    },
    [getUser, setDeactivateUserModalOpen],
  );

  const handleEditClick = useCallback(
    (id: string) => () => {
      getUser(id);
      setEditUserModalOpen(true);
    },
    [getUser, setEditUserModalOpen],
  );

  const handleAddSubmit = async (formValues: any, _form: any, cb: Function) => {
    const data = {
      ...formValues,
    };
    if (isOwner) {
      await createOwnSkyboxCompanyUser(data, cb);
    }
    if (isAdmin) {
      await createUser(data, cb);
    }
    setAddUserModalOpen(false);
    getUserList();
  };

  const handleUpdateSubmit = async (
    formValues: any,
    _form: any,
    cb: Function,
  ) => {
    const data = {
      ...formValues,
    };
    await updateUser(data, cb);
    setEditUserModalOpen(false);
    getUserList();
  };

  const handleDeactivateConfirm = async () => {
    if (user?.id) {
      await deactivateUser(user.id);
    }
    setDeactivateUserModalOpen(false);
    getUserList();
  };

  const handleCancelClick = () => {
    setAddUserModalOpen(false);
    setEditUserModalOpen(false);
    setDeactivateUserModalOpen(false);
  };
  const addButton = (
    <Box>
      <Button id="adduserButton" startIcon="+" onClick={handleAddClick}>
        {t('user.addNewUser')}
      </Button>
    </Box>
  );

  const COLUMNS = useMemo(() => {
    let cols = [
      {
        accessor: 'email',
        Header: t('user.usersName'),
        Cell: CellRenderers.nameOrEmail,
      },
      {
        accessor: 'id',
        Header: t('common.function'),
        Cell: ({ cell }: any) => (
          <T variant="body1">
            <Link onClick={handleEditClick(cell.value)}>
              {t('common.edit')}
            </Link>
            {' / '}
            <Link onClick={handledeactivateClick(cell.value)}>
              {t('common.deactivate')}
            </Link>
          </T>
        ),
      },
    ];

    if (isManager) {
      cols = [cols[0]!];
    }
    return cols;
  }, [handleEditClick, handledeactivateClick, t, isManager]);

  const renderAddUserModal = () => (
    <CommonModal open={addUserModalOpen} type="prompt">
      <UserForm
        onSubmit={handleAddSubmit}
        onCancel={handleCancelClick}
        isLoading={isLoading}
        isEdit={false}
        isOwner={isOwner}
        ownerList={ownerListPage}
      />
    </CommonModal>
  );

  const renderdeactivateUserModal = () => (
    <CommonModal
      open={deactivateUserModalOpen}
      type="prompt"
      title={t('user.deactivateUserConfirm')}
      confirmText={t('common.deactivate')}
      onConfirm={handleDeactivateConfirm}
      onCancel={handleCancelClick}
    >
      <T>{`${user?.firstName} ${user?.lastName}`}</T>
    </CommonModal>
  );

  return (
    <UsersLayout>
      <Box p={3}>
        <Box
          mb={3}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body2">
            {t('user.adminUserCount', { count: users?.length || 0 })}
          </Typography>
          {!isManager && addButton}
        </Box>
        <MaterialTable columns={COLUMNS} data={users} />
      </Box>
      {renderAddUserModal()}
      <EditUserModal
        onSubmit={handleUpdateSubmit}
        onCancel={handleCancelClick}
        open={editUserModalOpen}
        isLoading={isLoading}
        user={user}
      />
      {renderdeactivateUserModal()}
    </UsersLayout>
  );
});
