import { Box, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IconTextButton } from '../../components/IconTextButton/IconTextButton';
import { Layout } from '../../components/Layout';
import { CellRenderers } from '../../components/MaterialTable/CellRenderers';
import MaterialTable from '../../components/MaterialTable/MaterialTable';
import { SkyboxMap } from '../../components/SkyboxMap/SkyboxMap';
import { PATHS } from '../../constants/routes';
import { useStore } from '../../stores';
import { SortBy } from '../../types/Common';
import { getPathWithId } from '../../utils/navigationUtils';

export interface SkyboxesScreenProps {}

export const SkyboxesScreen: FC<SkyboxesScreenProps> = observer(() => {
  const PAGE_SIZE = 10;
  const {
    skyboxListPage,
    getSkyboxList,
    getMyActiveSkyboxes,
    myActiveSkyboxes,
    skyboxTotal,
  } = useStore('skyboxStore');
  const [currentPage, setCurrentPage] = useState(0);
  const [sortBy, setSortBy] = useState<SortBy>({ id: 'mapId', desc: false });
  const { isAdmin, isOwner } = useStore('authStore');
  const { t } = useTranslation();
  const history = useHistory();

  const fetchSkyboxes = useCallback(async () => {
    const skip = PAGE_SIZE * currentPage || 0;
    if (isAdmin) return getSkyboxList({ take: PAGE_SIZE, skip, ...sortBy });
    if (isOwner)
      return getMyActiveSkyboxes({ take: PAGE_SIZE, skip, ...sortBy });
  }, [
    getSkyboxList,
    getMyActiveSkyboxes,
    sortBy,
    currentPage,
    isAdmin,
    isOwner,
  ]);

  const onAddClick = () => {
    history.push(PATHS.skyboxes.add);
  };

  const COLUMNS = [
    {
      accessor: 'name',
      Header: t('common.name'),
      Cell: CellRenderers.linkIfActive((row) =>
        getPathWithId(PATHS.skyboxes.single, row.id),
      ),
    },
    {
      accessor: 'mapId',
      Header: t('common.id'),
      Cell: CellRenderers.linkIfActive((row) =>
        getPathWithId(PATHS.skyboxes.single, row.id),
      ),
    },
    {
      accessor: 'seatCount',
      Header: t('common.capacity'),
      Cell: CellRenderers.linkIfActive((row) =>
        getPathWithId(PATHS.skyboxes.single, row.id),
      ),
    },
    {
      accessor: 'active',
      Header: t('common.state'),
      Cell: CellRenderers.boolean('', t('skybox.deactivated')),
    },
  ];

  if (isAdmin) {
    COLUMNS.unshift({
      accessor: 'company.name',
      Header: t('common.owner'),
      Cell: CellRenderers.linkIfActive((row) =>
        getPathWithId(PATHS.skyboxes.single, row.id),
      ),
    });
  }

  const skyboxItems = isOwner ? myActiveSkyboxes : skyboxListPage;

  const onPageChange = async (pageIndex: number) => {
    if (pageIndex !== currentPage) {
      setCurrentPage(pageIndex);
    }
  };

  useEffect(() => {
    fetchSkyboxes();
  }, [fetchSkyboxes]);

  const onSortingChange = ({ sortBy }: { sortBy: SortBy[] }) => {
    setSortBy(sortBy[0]!);
  };

  return (
    <Layout headerText={t('sidemenu.skyboxes')}>
      <Box p={3}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
          <Box alignItems="center" display="flex">
            <Typography variant="body2">
              {t('skybox.skyboxCount', { count: skyboxTotal })}
            </Typography>
          </Box>

          <Box display="flex" alignItems="center">
            <SkyboxMap />
            {isAdmin && (
              <IconTextButton
                onClick={onAddClick}
                label="skybox.addNewSkybox"
                type="add"
                id={`add-new-skybox`}
              />
            )}
          </Box>
        </Box>

        <MaterialTable
          columns={COLUMNS}
          data={skyboxItems || []}
          onPageChange={onPageChange}
          onSortingChange={onSortingChange}
          totalCount={skyboxTotal}
          paginationEnabled
          getRowTestId={(rowData) => `skybox-row__${rowData.name}`}
        />
      </Box>
    </Layout>
  );
});
