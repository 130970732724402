import { Box, lighten, makeStyles, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from '../../stores';
import colors from '../../styles/colors';
import theme from '../../styles/theme';
import { InfoTooltip } from '../InfoTooltip';
import { NavMenuItemType } from './NavMenu';

interface NavMenuItemProps {
  item: NavMenuItemType;
  navigateTo: (item: NavMenuItemType) => void;
  collapsed: boolean;
}

const useStyles = makeStyles({
  listItemContainer: {
    display: 'flex',
    width: '100%',
  },
  iconContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    display: 'flex',
    paddingRight: theme.spacing(1),
    color: lighten(colors.accent.primary, 0.7),
    width: 30,
    height: 30,
  },
  menuItem: {
    color: lighten(colors.accent.primary, 0.7),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: lighten(colors.accent.primary, 0.1),
    },
  },
  menuItemActive: {
    cursor: 'pointer',
    color: colors.text.inverted,
    '& .MuiTypography-button': {
      fontWeight: 600,
    },
  },
  itemText: {
    fontSize: '1rem',
  },
  notificationIndicator: {
    height: 10,
    width: 10,
    borderRadius: 10,
    backgroundColor: colors.red7,
    position: 'absolute',
    left: -2,
    top: 4,
  },
});

export const NavMenuItem: React.FC<NavMenuItemProps> = observer(
  ({ navigateTo, item, collapsed }) => {
    const {
      commonStore: { toggleMobileDrawer },
    } = useStores();

    const classes = useStyles();
    const liClass = item.isActive ? classes.menuItemActive : classes.menuItem;
    const isLeftBorder = collapsed && item.isActive;
    const onListItemClick = () => {
      navigateTo(item);
      toggleMobileDrawer({ isOpen: false });
    };

    return (
      <li
        onClick={onListItemClick}
        className={liClass}
        style={{
          borderLeft: isLeftBorder ? '2px solid #fff' : 'none',
          width: collapsed ? 60 : 'auto',
        }}
      >
        <Box
          px={collapsed ? 0 : 3}
          paddingTop={2}
          paddingBottom={2}
          className={classes.listItemContainer}
          style={{
            paddingLeft: collapsed ? 0 : theme.spacing(4),
            justifyContent: collapsed ? 'center' : 'inherit',
          }}
        >
          {!collapsed && item.iconComponent && (
            <Box className={classes.iconContainer}>
              {item.hasNotification && (
                <Box className={classes.notificationIndicator} />
              )}
              {item.iconComponent}
            </Box>
          )}
          {!collapsed && (
            <Typography variant="button" className={classes.itemText}>
              {item.label}
            </Typography>
          )}
          {collapsed && (
            <InfoTooltip tooltipText={item.label} hideInfoIcon>
              <Box className={classes.iconContainer}>
                {item.hasNotification && (
                  <Box className={classes.notificationIndicator} />
                )}
                {item.iconComponent}
              </Box>
            </InfoTooltip>
          )}
        </Box>
      </li>
    );
  },
);
