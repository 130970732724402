import { CUSTOMER_ID } from '../constants/siteSettings';
import {
  IAdminUser,
  IGetResetPassword,
  ILoginCredentials,
  IResetPassword,
} from '../types/User';
import { api, getAuthConfig, getPath } from './api';

const API_PATHS = Object.freeze({
  register: '/customer/{customerId}/admin-user',
  login: '/auth/admin/login',
  logout: '/auth/admin/logout',
  refreshAuthToken: '/auth/admin/refresh',
  me: '/customer/{customerId}/admin-user/me',
  resetPasswordRequest: '/customer/{customerId}/admin-user/passwordReset/reset',
  resetPassword: '/customer/{customerId}/admin-user/passwordReset',
});

class AuthServiceModel {
  login = async (credentials: ILoginCredentials) => {
    const loginCredentialsWithCustomer = {
      ...credentials,
      ...{ customer: CUSTOMER_ID },
    };
    return api.post<{ user: IAdminUser; authToken: string }>(
      API_PATHS.login,
      loginCredentialsWithCustomer,
    );
  };

  logout = async () => {
    return api.post(API_PATHS.logout, { customer: CUSTOMER_ID });
  };

  refreshAuthToken = async () => {
    return api.post<{ authToken: string }>(API_PATHS.refreshAuthToken, {
      customer: CUSTOMER_ID,
    });
  };

  getMe = async () => api.get(getPath(API_PATHS.me), getAuthConfig());

  getResetPasswordEmail = async (data: IGetResetPassword) =>
    api.post(getPath(API_PATHS.resetPassword), data);

  resetPassword = async (data: IResetPassword) =>
    api.post(getPath(API_PATHS.resetPasswordRequest), data);
}

const AuthService = new AuthServiceModel();

export default AuthService;
