import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Drawer, Hidden, makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import ReactStickyBox from 'react-sticky-box';
import { ROUTES } from '../../constants';
import { useStores } from '../../stores/index';
import colors from '../../styles/colors';
import { IRoute } from '../../types/Common';
import { UserRole } from '../../types/User';
import { NavMenu } from '../NavMenu';

const findRoute = (pathname: string) => {
  let route;
  const locationFirstPart = pathname.split('/')[1];
  for (let i = 0; i < ROUTES.length; i++) {
    if (locationFirstPart === ROUTES[i]!.href.split('/')[1]) {
      route = ROUTES[i];
      break;
    }
  }
  return route;
};

const useStyles = makeStyles({
  logoContainer: {
    alignSelf: 'center',
    borderRadius: 2,
    backgroundColor: colors.background.bg1,
    '& img': {
      maxWidth: 180,
    },
  },
  sidebar: {
    transition: 'min-width 150ms',
    backgroundColor: colors.accent.primary,
  },
  collapseButton: {
    zIndex: 11,
    position: 'absolute',
    width: 24,
    height: 24,
    padding: 0,
    minWidth: 0,
    borderRadius: 24,
    right: -(24 / 2),
    top: 60,
    //transition: 'opacity 250ms 150ms',
  },
});

const Menu: React.FC = observer(() => {
  const {
    authStore: { isOwner },
    authStore: { currentUserRoles },
    commonStore: {
      menuCollapsed,
      setMenuCollapsed,
      uiState: { mobileDrawerActive },
    },
    bulletinStore: { unreadBulletins },
  } = useStores();
  const [hovered, setHovered] = useState(false);
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const currentRoute = findRoute(pathname);
  const classes = useStyles();

  const itemHasNotification = (item: IRoute) => {
    if (item.name === 'bulletins' && unreadBulletins && isOwner) return true;
    return false;
  };

  const filteredRoutes = ROUTES.filter((route) => {
    // filter out routes that user has no rights
    let hasRights = true;
    if (route.roles) {
      hasRights = false;
      route.roles.forEach((role: UserRole) => {
        if (currentUserRoles.includes(role)) hasRights = true;
      });
    }
    // filter out routes that are not menuItems
    return hasRights && route.menuItem;
  }).map((route) => ({
    href: route.href,
    label: t(`sidemenu.${route.name}`),
    isActive: route.menuGroup === currentRoute?.menuGroup,
    iconComponent: route.iconComponent,
    hasNotification: itemHasNotification(route),
  }));

  const isCollapseButtonVisible = useMemo(() => {
    if (!menuCollapsed && hovered) return true;
    if (menuCollapsed) return true;
    return false;
  }, [menuCollapsed, hovered]);

  const collapseButtonIcon = useMemo(() => {
    const icon = menuCollapsed ? faAngleRight : faAngleLeft;
    return <FontAwesomeIcon icon={icon} />;
  }, [menuCollapsed]);

  const onMouseOver = () => setHovered(true);
  const onMouseOut = () => setHovered(false);

  const toggleMenu = () => {
    setMenuCollapsed(!menuCollapsed);
  };

  return (
    <Box
      id="sidebar"
      className={`${classes.sidebar} no-print`}
      style={{ minWidth: menuCollapsed ? 30 : 275 }}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      component="aside"
    >
      <Box position="relative" height="100vh">
        {!mobileDrawerActive && (
          <Button
            onClick={toggleMenu}
            variant="outlined"
            style={{ opacity: isCollapseButtonVisible ? 1 : 0 }}
            className={classes.collapseButton}
          >
            {collapseButtonIcon}
          </Button>
        )}
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
        >
          <ReactStickyBox offsetTop={60}>
            <NavMenu items={filteredRoutes} collapsed={menuCollapsed} />
          </ReactStickyBox>
        </Box>
      </Box>
    </Box>
  );
});

// SideMenu component
const SideMenu: React.FC = observer(() => {
  const {
    commonStore: {
      uiState: { mobileDrawerActive },
      toggleMobileDrawer,
    },
  } = useStores();

  return (
    <>
      <Hidden mdUp>
        <Drawer
          anchor={'left'}
          open={mobileDrawerActive}
          onClose={() => toggleMobileDrawer({ isOpen: !mobileDrawerActive })}
        >
          <Menu />
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Menu />
      </Hidden>
    </>
  );
});

export default SideMenu;
