import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import EventService from '../services/EventService';
import { StoreState } from '../types/Common';
import {
  CustomerEvents,
  GetEventSeriesDTO,
  IEvent,
  IEventSeries,
  UpdateEventInformationDTO,
} from '../types/Event';
import ToastStore from './ToastStore';

export interface IEventStore {
  allEvents?: IEvent[];
  allEventsCount: number;
  customerEvents?: CustomerEvents;
  event?: IEvent;
  eventSeries?: IEventSeries[];
  eventSeriesCount: number;
  singleEventSeries?: IEventSeries;
  isLoading: boolean;
  state: StoreState;
  reset: () => void;
  getEvent: (
    eventSeriesId: number,
    eventId: number,
    publicQuery?: boolean,
  ) => void;
  getEventSeries: (params?: GetEventSeriesDTO, publicQuery?: boolean) => void;
  getSingleEventSeries: (
    eventSeriesId: number | string,
    publicQuery?: boolean,
  ) => void;
  updateCustomerEvents: (data: CustomerEvents) => void;
  getCustomerEvents: () => void;
  updatePrices: (eventId: number) => void;
  updateEventInformation: (
    data: UpdateEventInformationDTO,
    hideSuccess?: boolean,
  ) => void;
}

class EventModel implements IEventStore {
  allEvents: IEventStore['allEvents'] = undefined;

  allEventsCount: IEventStore['allEventsCount'] = 0;

  event: IEventStore['event'] = undefined;

  eventSeries: IEventStore['eventSeries'] = undefined;

  eventSeriesCount: IEventStore['eventSeriesCount'] = 0;

  singleEventSeries: IEventStore['singleEventSeries'] = undefined;

  customerEvents: IEventStore['customerEvents'] = undefined;

  state: StoreState = 'Idle';

  constructor() {
    makeObservable(this, {
      allEvents: observable,
      event: observable,
      singleEventSeries: observable,
      eventSeriesCount: observable,
      eventSeries: observable,
      state: observable,

      // actions
      getEvent: action,
      getEventSeries: action,
      getSingleEventSeries: action,
      reset: action,
      updateEventInformation: action,
      setDefaultListsForEvent: action,

      // computed
      isLoading: computed,
      eventHasTickets: computed,
      eventHasHotels: computed,
      eventHasProducts: computed,
    });
  }

  reset = () => {
    runInAction(() => {
      this.allEvents = undefined;
      this.allEventsCount = 0;
      this.event = undefined;
      this.eventSeries = undefined;
      this.eventSeriesCount = 0;
      this.singleEventSeries = undefined;
      this.state = 'Idle';
    });
  };

  get eventHasTickets() {
    if (!this.event) return true;
    return this.event.priceLevels && !!this.event.priceLevels.length;
  }

  get eventHasHotels() {
    if (!this.event) return true;
    return !!this.event.hotelLists?.length;
  }

  get eventHasProducts() {
    if (!this.event) return true;
    return !!this.event.productLists?.length;
  }

  get isLoading() {
    return this.state === 'Loading';
  }

  getEvent = async (
    eventSeriesId: number | string,
    eventId: number | string,
    publicQuery?: boolean,
  ) => {
    this.state = 'Loading';
    try {
      const res = (await EventService.getEvent(
        String(eventSeriesId),
        String(eventId),
        publicQuery,
      )) as any;
      runInAction(() => {
        this.event = res.data;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        ToastStore.showError('errors.events.getEvent');
        this.state = 'Error';
      });
      throw error;
    }
  };

  getEventSeries = async (
    params?: GetEventSeriesDTO,
    publicQuery?: boolean,
  ) => {
    this.state = 'Loading';
    try {
      const res = (await EventService.getEventSerie(
        params,
        publicQuery,
      )) as any;
      runInAction(() => {
        this.eventSeries = res.data.results;
        this.eventSeriesCount = res.data.total;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        ToastStore.showError('errors.events.getEventSerie');
        this.state = 'Error';
      });
      throw error;
    }
  };

  /**
   * Loads event series (single EventSeries)
   */
  getSingleEventSeries = async (eventSeriesId: number | string) => {
    this.state = 'Loading';
    try {
      const res = await EventService.getEventSeries(String(eventSeriesId));
      runInAction(() => {
        this.singleEventSeries = res.data.results;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        ToastStore.showError('errors.events.getEventSeries');
        this.state = 'Error';
      });
      throw error;
    }
  };

  getCustomerEvents = async () => {
    this.state = 'Loading';
    try {
      const res = await EventService.getCustomerEventVisibility();
      runInAction(() => {
        this.customerEvents = res.data;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        ToastStore.showError('errors.events.getCustomerEvents');
        this.state = 'Error';
      });
      throw error;
    }
  };

  updateCustomerEvents = async (data: CustomerEvents) => {
    this.state = 'Loading';
    try {
      await EventService.updateCustomerEventVisibility(data);
      runInAction(() => {
        this.state = 'Success';
        ToastStore.showSuccess('successes.events.updateCustomerEvents');
      });
    } catch (error) {
      runInAction(() => {
        ToastStore.showError('errors.events.updateCustomerEvents');
        this.state = 'Error';
      });
      throw error;
    }
  };

  updateEventInformation = async (
    data: UpdateEventInformationDTO,
    hideSuccess?: boolean,
  ) => {
    this.state = 'Loading';
    try {
      await EventService.updateEventInformation(data);
      runInAction(() => {
        this.state = 'Success';
        if (!hideSuccess) ToastStore.showSuccess('successes.common.save');
      });
    } catch (error) {
      runInAction(() => {
        ToastStore.showError('errors.common.save');
        this.state = 'Error';
      });
      throw error;
    }
  };

  updatePrices = async (eventId: number) => {
    this.state = 'Loading';
    try {
      await EventService.updatePrices(eventId);
      runInAction(() => {
        this.state = 'Success';
        ToastStore.showSuccess('successes.events.updatePrices');
      });
    } catch (error) {
      runInAction(() => {
        ToastStore.showError('errors.events.updatePrices');
        this.state = 'Error';
      });
      throw error;
    }
  };

  setDefaultListsForEvent = async (eventSeriesId: number, eventId: number) => {
    this.state = 'Loading';
    try {
      await EventService.setDefaultListsForEvent({ eventSeriesId, eventId });
      await this.getEvent(eventSeriesId, eventId);
      runInAction(() => {
        this.state = 'Success';
        ToastStore.showSuccess('successes.events.setDefaultListsForEvent');
      });
    } catch (error) {
      runInAction(() => {
        ToastStore.showError('errors.events.setDefaultListsForEvent');
        this.state = 'Error';
      });
      throw error;
    }
  };
}

const EventStore = new EventModel();

export default EventStore;
