import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  Typography as T,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { FC, useState } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IAsset } from '../../types/Common';
import { useStyles } from './styles';

const Wrapper = styled(Box)`
  min-width: 100%;
  position: relative;
`;

interface ImageUploadProps {
  name: string;
  label?: string;
  addLabel?: string;
  previewUrl?: string | null;
  className?: string;
  multiple?: boolean;
  assets?: IAsset[];
  instruction?: string;
  onChange: (data: any) => void;
  onDelete?: (data: any) => void;
  onAdditionalImageDelete?: (data: any) => void;
  disabled?: boolean;
  helperText?: string;
  maxAssetCount?: number;
}

export const ImageUpload: FC<ImageUploadProps> = ({
  name,
  disabled,
  assets,
  label,
  addLabel,
  onChange,
  previewUrl,
  className,
  onDelete,
  instruction,
  onAdditionalImageDelete,
  helperText,
  maxAssetCount,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [error, setError] = useState<string | undefined>();

  const handleImageChange = (files: any) => {
    const file = files[0];

    if (file.size > 1024 * 1024 * 10) {
      setError(t('validation.fileTooBig'));
      return;
    }

    setError(undefined);
    const reader = new FileReader();
    reader.onloadend = () =>
      onChange({ image: file, imagePreviewUrl: reader.result });
    reader.readAsDataURL(file);
  };

  const renderLabel = () => {
    return (
      label && (
        <Box mb={3}>
          <InputLabel>{label}</InputLabel>
        </Box>
      )
    );
  };

  const renderMultipleImages = () => {
    return (
      <Box ml={3} className={classes.previewUrlsContainer}>
        {assets?.map((asset) => {
          return (
            <Box
              key={asset.id}
              mr={3}
              mb={3}
              className={classes.previewGridImage}
              style={{ backgroundImage: `url('${asset.source}')` }}
            >
              {renderDelete(asset.id)}
            </Box>
          );
        })}
      </Box>
    );
  };

  const renderDelete = (id?: string) => {
    const fn = !id
      ? onDelete
      : () => onAdditionalImageDelete && onAdditionalImageDelete(id);
    return (
      <button type="button" className={classes.deleteButton} onClick={fn}>
        <DeleteIcon color="primary" className={classes.icon} />
      </button>
    );
  };

  const renderContent = () => {
    return (
      <Field
        name={`${name}.source`}
        render={({ input }) => {
          if (input.value || previewUrl) {
            return (
              <Box className={classes.previewContainer}>
                {renderDelete()}
                <img
                  className={classes.preview}
                  src={input.value || previewUrl}
                  alt="preview"
                />
              </Box>
            );
          }
          return (
            <Box display="flex" position="relative">
              {(disabled ||
                (maxAssetCount &&
                  assets &&
                  assets.length >= maxAssetCount)) && (
                <Box className={classes.disabledOverlay} />
              )}
              <Box>
                <Box
                  component="label"
                  className={classes.button}
                  // @ts-ignore
                  htmlFor={`${name}-upload-file`}
                >
                  <AddIcon className={classes.image} />
                  <span className={classes.label}>
                    {addLabel || t('common.addImage')}
                  </span>
                </Box>
                <input
                  className={classes.hiddenInput}
                  id={`${name}-upload-file`}
                  type="file"
                  disabled={disabled}
                  onChange={(e: any) => {
                    handleImageChange(e.target.files);
                  }}
                />
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
              </Box>
              {assets && renderMultipleImages()}
            </Box>
          );
        }}
      />
    );
  };

  return (
    <Wrapper className={className}>
      <FormControl fullWidth>
        {renderLabel()}
        {renderContent()}
        {instruction && <T>{instruction}</T>}
        {error && <T color="error">{error}</T>}
      </FormControl>
    </Wrapper>
  );
};
