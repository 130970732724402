import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { ValidationErrors } from 'final-form';
import { observer } from 'mobx-react-lite';
import { TextField } from 'mui-rff';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import validator from 'validator';
import SkyboxService from '../../services/SkyboxService';
import { useStores } from '../../stores/index';
import ToastStore from '../../stores/ToastStore';
import { ISkyboxInvite } from '../../types/Skybox';
import { ITicketPurchase } from '../../types/Ticket';
import { ColorBox } from '../ColorBox/ColorBox';
import MainSpinner from '../Layout/MainSpinner';
import { CommonModal } from '../Modals';
import { ToggleableTextField } from '../TogglableTextField';

interface TicketDeliveryTableProps {
  tickets: ITicketPurchase[];
  purchaseId: string;
}

type TicketDeliveryFormValues = {
  guest: { [key: string]: string };
  message?: string;
};

const getPrefixedId = (id: number) => `ticket${id}`;
const GUEST = 'guest';

const validateEmail = (value: string) => {
  if (!value) return 'validation.required';
  return validator.isEmail(value) ? false : 'validation.email';
};

const TicketDeliveryForm = ({
  purchaseId,
  tickets,
  formValues,
  formErrors,
  skyboxInvites,
  onSendSuccess,
}: {
  purchaseId: string;
  tickets: ITicketPurchase[];
  formValues: TicketDeliveryFormValues;
  formErrors: ValidationErrors;
  skyboxInvites: ISkyboxInvite[];
  onSendSuccess: () => void;
}) => {
  const { t } = useTranslation();
  const {
    skyboxStore: { createSkyboxInvite, downloadSkyboxTicket },
  } = useStores();
  const [tempInvite, setTempInvite] = useState<
    { id: string; ticketId: number; index: number } | undefined
  >();
  const [sendingInProgress, setSendingInProgress] = useState(false);

  const onModalConfirm = async () => {
    if (!tempInvite) return;
    const { id, ticketId, index } = tempInvite;
    await onTicketSend(id, ticketId, index, formValues, true);
    setTempInvite(undefined);
  };

  const onModalCancel = () => {
    setTempInvite(undefined);
  };

  const onDownloadClick = (index: number) => {
    const invite = skyboxInvites?.find((i) => i.ticketIndex === index);

    if (!invite) {
      ToastStore.showError('errors.order.noInviteFound');
      return;
    }
    downloadSkyboxTicket(invite.code, purchaseId, invite.ticketIndex);
  };

  const onTicketSend = async (
    id: string,
    ticketId: number,
    index: number,
    formValues: TicketDeliveryFormValues,
    forceSend: boolean = false,
  ) => {
    const email = formValues.guest?.[getPrefixedId(ticketId)] || undefined;
    if (!email) {
      return;
    }

    const ticketIndex = tickets?.findIndex(
      (ticket) => ticket.ticketId === ticketId,
    );

    if (ticketIndex >= 0 && skyboxInvites?.length && !forceSend) {
      const currentInvite = skyboxInvites[ticketIndex];
      if (currentInvite?.acceptedAt) {
        setTempInvite({ id, ticketId, index });
        return;
      }
    }

    setSendingInProgress(true);

    await createSkyboxInvite({
      email,
      ticketId: id,
      purchaseId: purchaseId,
      ticketNumber: index,
      message: formValues?.message,
    })
      .then(() => {
        onSendSuccess();
      })
      .finally(() => {
        setSendingInProgress(false);
      });
  };

  const Status = ({ index }: { index: number }) => {
    const invite = skyboxInvites?.find(
      (invite) => invite.ticketIndex === index,
    );
    const isSentInvite = invite && invite.email;
    let text = isSentInvite ? 'sent' : 'unsent';

    if (invite?.acceptedAt) text = 'accepted';
    if (invite?.rejectedAt) text = 'rejected';

    return (
      <ColorBox
        color={isSentInvite && invite && !invite.rejectedAt ? 'green' : 'red'}
        display="flex"
        whiteText
        justifyContent="center"
      >
        {t(`orders.add.delivery.inviteStatus.${text}`)}
      </ColorBox>
    );
  };

  const SendButton = ({
    ticket,
    index,
  }: {
    ticket: ITicketPurchase;
    index: number;
  }) => {
    const invite = skyboxInvites?.find(
      (invite) => invite.ticketIndex === index,
    );
    const guestEmail =
      formValues.guest?.[getPrefixedId(ticket.ticketId)] || undefined;
    const inResendMode = guestEmail && invite?.email === guestEmail;
    const label = inResendMode ? 'resendTicket' : 'sendTicket';
    return (
      <Button
        disabled={
          !!(
            formErrors &&
            formErrors.guest &&
            (formErrors.guest?.[getPrefixedId(ticket.ticketId)] || undefined)
          ) || sendingInProgress
        }
        onClick={() =>
          onTicketSend(ticket.id, ticket.ticketId, index, formValues)
        }
        variant="text"
      >
        {t(`orders.add.delivery.table.${label}`)}
      </Button>
    );
  };

  return (
    <Box component="form" mt={5} onSubmit={(e) => e.preventDefault()}>
      <Typography variant="h3">{t('orders.add.delivery.message')}</Typography>
      <Box mt={3} mb={3}>
        <TextField
          name="message"
          multiline
          rows={8}
          helperText={t('orders.add.delivery.messageInstruction')}
        />
      </Box>

      <Typography variant="h3">
        {t('orders.add.delivery.ticketDelivery')}
      </Typography>

      <Box mt={3}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('orders.add.delivery.table.guest')}</TableCell>
              <TableCell>{t('common.status')}</TableCell>
              <TableCell>{<Box ml={3}>{t('common.function')}</Box>}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tickets.map((ticket, index) => {
              return (
                <TableRow key={ticket.ticketId}>
                  <TableCell>
                    <ToggleableTextField
                      name={`${GUEST}.${getPrefixedId(ticket.ticketId)}`}
                      validate={validateEmail}
                      placeholder={t(
                        'orders.add.delivery.table.textfieldPlaceholder',
                      )}
                      onSubmit={() => {}}
                    />
                  </TableCell>
                  <TableCell>
                    <Status index={index} />
                  </TableCell>
                  <TableCell>
                    <SendButton index={index} ticket={ticket} />
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => onDownloadClick(index)}
                      variant="text"
                    >
                      {t('orders.add.delivery.table.downloadTicket')}
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>

      <CommonModal
        onConfirm={onModalConfirm}
        onCancel={onModalCancel}
        title={t('orders.add.delivery.confirmModal.title')}
        confirmText={t('orders.add.delivery.confirmModal.confirm')}
        open={!!tempInvite}
      >
        <>{t('orders.add.delivery.confirmModal.body')}</>
      </CommonModal>
    </Box>
  );
};

export const TicketDeliveryTable = observer(
  ({ tickets, purchaseId }: TicketDeliveryTableProps) => {
    const [invites, setInvites] = useState<ISkyboxInvite[] | undefined>(
      undefined,
    );

    const initialValues = useMemo(() => {
      if (invites && tickets) {
        const initialValues: TicketDeliveryFormValues = { guest: {} };

        for (const invite of invites) {
          const ticketId = tickets?.[invite.ticketIndex]?.ticketId || undefined;
          if (ticketId && invite.email) {
            initialValues.guest[getPrefixedId(ticketId)] = invite.email;
          }
        }

        return initialValues;
      }
    }, [invites, tickets]);

    const fetchInvites = useCallback(() => {
      SkyboxService.getSkyboxInvites(purchaseId).then((v) => {
        setInvites(v.data);
      });
    }, [setInvites, purchaseId]);

    useEffect(() => {
      if (purchaseId) {
        fetchInvites();
      }
    }, [fetchInvites, purchaseId]);

    return (
      <Box>
        {initialValues && invites ? (
          <Form
            onSubmit={() => {}}
            initialValues={initialValues}
            keepDirtyOnReinitialize={true}
            render={({ errors, values }) => {
              return (
                <TicketDeliveryForm
                  formValues={values as TicketDeliveryFormValues}
                  formErrors={errors}
                  purchaseId={purchaseId}
                  skyboxInvites={invites || []}
                  tickets={tickets}
                  onSendSuccess={() => fetchInvites()}
                />
              );
            }}
          />
        ) : (
          <MainSpinner />
        )}
      </Box>
    );
  },
);
