import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '../../components/Layout';
import { Link } from '../../components/Link';
import { useStore } from '../../stores';
import { IGetResetPassword } from '../../types/User';
import RequestResetPasswordForm from './RequestResetPasswordForm';

interface RequestResetPasswordScreenProps {}

const useStyles = makeStyles({
  fullHeight: {
    height: '100%',
    minHeight: '100vh',
  },
  center: {
    textAlign: 'center',
  },
  formContainer: {
    height: '100%',
    width: '100%',
    maxWidth: '960px',
  },
});

export const RequestResetPasswordScreen: FC<RequestResetPasswordScreenProps> =
  observer(() => {
    const { getResetPasswordEmail, isLoading } = useStore('authStore');
    const { t } = useTranslation();
    const classes = useStyles();

    const onRequestResetPasswordSubmit = (formValues: IGetResetPassword) => {
      getResetPasswordEmail(formValues);
    };

    return (
      <Layout>
        <Grid container justify="center" className={classes.fullHeight}>
          <Box className={classes.formContainer}>
            <Grid
              container
              justify="center"
              alignItems="center"
              className={classes.fullHeight}
            >
              <Grid item xs={12} md={8} lg={6}>
                <Container>
                  <Box mb={4} className={classes.center}>
                    <Typography variant="h4">
                      {t('login.boxManagement')}
                    </Typography>
                  </Box>
                  <Box mb={4} className={classes.center}>
                    <Typography variant="h1">
                      {t('login.forgotPassword')}
                    </Typography>
                  </Box>
                  <Box>
                    <RequestResetPasswordForm
                      onSubmit={onRequestResetPasswordSubmit}
                      isLoading={isLoading}
                    />
                  </Box>
                  <Box mt={4} className={classes.center}>
                    <Link to="/login">{t('login.backToLogin')}</Link>
                  </Box>
                </Container>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Layout>
    );
  });
