/* eslint-disable max-len */
/* eslint-disable implicit-arrow-linebreak */
import {
  CheckTimeSlotDTO,
  CreateCalendarReservationDTO,
  GetAllCalendarReservationsDTO,
} from '../types/Calendar';
import { api, getAuthConfig, getPath } from './api';

const API_PATHS = Object.freeze({
  single: '/customer/{customerId}/reservation/{itemId}',
  all: '/customer/{customerId}/reservation',
  checkSlot: '/customer/{customerId}/reservation/checkSlot',
  blockedSkyboxes: '/customer/{customerId}/reservation/blockedSkyboxes',
});

class CalendarReservationServiceModel {
  getAllCalendarReservations = (params: GetAllCalendarReservationsDTO) =>
    api.get(getPath(API_PATHS.all), getAuthConfig(false, params));

  getCalendarReservation = (id: string) =>
    api.get(getPath(API_PATHS.single, id), getAuthConfig());

  updateCalendarReservation = (id: string, data: any) =>
    api.put(getPath(API_PATHS.single, id), data, getAuthConfig());

  createCalendarReservation = (data: CreateCalendarReservationDTO) =>
    api.post(getPath(API_PATHS.all), data, getAuthConfig());

  deleteCalendarReservation = (id: string) =>
    api.delete(getPath(API_PATHS.single, id), getAuthConfig());

  checkCalendarSlotForReservation = (data: CheckTimeSlotDTO) =>
    api.post(getPath(API_PATHS.checkSlot), data, getAuthConfig());

  getBlockedSkyboxes = (data: { startDate: string; endDate: string }) =>
    api.get(getPath(API_PATHS.blockedSkyboxes), getAuthConfig(false, data));
}

const CalendarReservationService = new CalendarReservationServiceModel();

export default CalendarReservationService;
