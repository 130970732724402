import { faUser } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  darken,
  Grid,
  makeStyles,
  Typography as T,
  Typography,
} from '@material-ui/core';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from '../../styles';
import colors from '../../styles/colors';
import { IAdminUser } from '../../types/User';

interface OwnerUsersFormProps {
  ownerUsers?: IAdminUser[];
  isLoading: boolean;
  onAddOwnerUser: (user: IAdminUser) => void;
  onDeleteOwnerUser: (id: string) => void;
}

const useStyles = makeStyles({
  userIcon: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  deleteIcon: {
    backgroundColor: colors.text.body2,
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: darken(colors.text.body2, 0.1),
    },
  },
  user: {
    borderColor: colors.stroke,
    backgroundColor: colors.background.bg2,
    border: '1px solid',
  },
});

export const OwnerUsersForm: FC<OwnerUsersFormProps> = ({ ownerUsers }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6}>
            <Box pt={1} mb={2}>
              <T variant="h2">{t('owner.users')}</T>
            </Box>
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
      </Grid>
      {ownerUsers?.map((user) => (
        <Grid item xs={12} key={user.email}>
          <Box
            p={1}
            pl={2}
            pr={2}
            mb={1}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className={classes.user}
          >
            <Box display="flex">
              <Box mr={1}>
                <FontAwesomeIcon icon={faUser} />
              </Box>
              <Typography>{`${user.firstName} ${user.lastName}`}</Typography>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
